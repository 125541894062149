import { useEffect } from "react";

import "./css/App.css";

import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "./components/Navigation/Navbar";
import Header from "./components/Landing/Header";
import Trust from "./components/Landing/Trust";
import Stats from "./components/Landing/Stats";
import Footer from "./components/Navigation/Footer";
import { CookiesProvider } from "react-cookie"

import SmoothScroll from './components/Scroll/ScrollMotion'
import { GetAllStats } from "./utils/request/globalStats"
import { CheckUserLogged } from "./utils/request/userInfo"
function HomePage() {
  useEffect(() => {
    AOS.init({
      once: true,
      easing: "ease-in-out"
    });
  }, []);


  useEffect(() => {
    (async () => {
      await GetAllStats()
      await CheckUserLogged()
    })();
  })


  return (
    <>
      <CookiesProvider>
        <SmoothScroll>
          <Navbar></Navbar>
          <Header></Header>
          <main className="mt-16 flex flex-col gap-20 pb-20">
            <Trust></Trust>
            <Stats></Stats>
          </main>
          <Footer></Footer>
        </SmoothScroll>
      </CookiesProvider>
    </>
  );
}

export default HomePage;
