export function ProfileButton({ icon, title, subtitle, href, big }: any) {
  return (
    <a
      href={href}
      className={`${big ? "md:col-span-2" : "md:col-span-1"} flex flex-row max-sm:flex-col max-sm:text-center items-center justify-start gap-3 rounded-lg bg-gray-100 border border-gray-200 px-3 py-4 hover:shadow-md hover:bg-gray-200/75 duration-100`}
    >
      {icon}
      <div>
        <h3 className="text-base font-medium text-navy-700">{title}</h3>
        <p className="text-sm text-gray-600">
          {subtitle}
        </p>
      </div>
    </a>
  );
}
