import { useEffect, useState } from "react";
import { NotificationIndicator } from "../Customization/NotificationIndicator";
//@ts-ignore
import logo from "../../assets/logos/logo.webp"

import secureLocalStorage from "react-secure-storage"
import { Cart, ICart } from "../../../../Global/classes/Cart.js";
import { IWishlist, Wishlist } from "../../../../Global/classes/Wishlist.js";



export function NavbarItems() {
  const loggedData = secureLocalStorage.getItem("logged") != null ? secureLocalStorage.getItem("logged") as { logged: boolean } : { logged: false }
  const cartData = secureLocalStorage.getItem("cart") != null ? secureLocalStorage.getItem("cart") as ICart : new Cart()
  const wishlistData = secureLocalStorage.getItem("wishlist") != null ? secureLocalStorage.getItem("wishlist") as IWishlist : new Wishlist()

  const [isLogged, setLogged] = useState(loggedData.logged)
  const [cartAmount, setCartAmount] = useState(cartData.products.length)
  const [wishlistAmount, setWishlistAmount] = useState(wishlistData.products.length)


  return (
    <div className="flex items-center">
      <a href="/profile/balance" className="relative sm:mr-8 mr-6">
        $0.00
      </a>
      <a href="wishlist" className="relative sm:mr-8 mr-6">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          width="26px"
          height="26px"
          className="cursor-pointer text-indigo-500 hover:text-indigo-800 duration-75 inline-block"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M11.2691 4.41115C11.5006 3.89177 11.6164 3.63208 11.7776 3.55211C11.9176 3.48263 12.082 3.48263 12.222 3.55211C12.3832 3.63208 12.499 3.89177 12.7305 4.41115L14.5745 8.54808C14.643 8.70162 14.6772 8.77839 14.7302 8.83718C14.777 8.8892 14.8343 8.93081 14.8982 8.95929C14.9705 8.99149 15.0541 9.00031 15.2213 9.01795L19.7256 9.49336C20.2911 9.55304 20.5738 9.58288 20.6997 9.71147C20.809 9.82316 20.8598 9.97956 20.837 10.1342C20.8108 10.3122 20.5996 10.5025 20.1772 10.8832L16.8125 13.9154C16.6877 14.0279 16.6252 14.0842 16.5857 14.1527C16.5507 14.2134 16.5288 14.2807 16.5215 14.3503C16.5132 14.429 16.5306 14.5112 16.5655 14.6757L17.5053 19.1064C17.6233 19.6627 17.6823 19.9408 17.5989 20.1002C17.5264 20.2388 17.3934 20.3354 17.2393 20.3615C17.0619 20.3915 16.8156 20.2495 16.323 19.9654L12.3995 17.7024C12.2539 17.6184 12.1811 17.5765 12.1037 17.56C12.0352 17.5455 11.9644 17.5455 11.8959 17.56C11.8185 17.5765 11.7457 17.6184 11.6001 17.7024L7.67662 19.9654C7.18404 20.2495 6.93775 20.3915 6.76034 20.3615C6.60623 20.3354 6.47319 20.2388 6.40075 20.1002C6.31736 19.9408 6.37635 19.6627 6.49434 19.1064L7.4341 14.6757C7.46898 14.5112 7.48642 14.429 7.47814 14.3503C7.47081 14.2807 7.44894 14.2134 7.41394 14.1527C7.37439 14.0842 7.31195 14.0279 7.18708 13.9154L3.82246 10.8832C3.40005 10.5025 3.18884 10.3122 3.16258 10.1342C3.13978 9.97956 3.19059 9.82316 3.29993 9.71147C3.42581 9.58288 3.70856 9.55304 4.27406 9.49336L8.77835 9.01795C8.94553 9.00031 9.02911 8.99149 9.10139 8.95929C9.16534 8.93081 9.2226 8.8892 9.26946 8.83718C9.32241 8.77839 9.35663 8.70162 9.42508 8.54808L11.2691 4.41115Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>{" "}
          </g>
        </svg>
        {isLogged && wishlistAmount > 0 ?
          <NotificationIndicator amount={wishlistAmount} ></NotificationIndicator>
          :
          null
        }


      </a>
      <a href="/cart" className="relative sm:mr-8 mr-6">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          width="26px"
          height="26px"
          className="cursor-pointer text-indigo-500 hover:text-indigo-800 duration-75 inline-block"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M3 3H4.37144C5.31982 3 6.13781 3.66607 6.32996 4.59479L8.67004 15.9052C8.86219 16.8339 9.68018 17.5 10.6286 17.5H17.5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>{" "}
            <path
              d="M6.82422 7H19.6743C20.3386 7 20.8183 7.6359 20.6358 8.27472L19.6217 11.8242C19.2537 13.1121 18.0765 14 16.7371 14H8.27734"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>{" "}
            <circle
              cx="16.5"
              cy="20.5"
              r="0.5"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></circle>{" "}
            <circle
              cx="0.5"
              cy="0.5"
              r="0.5"
              transform="matrix(1 0 0 -1 10 21)"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></circle>{" "}
          </g>
        </svg>
        {isLogged && cartAmount > 0 ?
          <NotificationIndicator amount={cartAmount} ></NotificationIndicator>
          :
          null
        }
      </a>

      <a
        href={isLogged ? "/profile" : "/signin"}
        className="inline-block cursor-pointer border-gray-300"
      >
        {isLogged ? <img src={logo} alt="" width="26px"
          height="26px" /> :

          <svg
            viewBox="0 0 24 24"
            width="32px"
            height="32px"
            fill="currentColor"
            className="text-indigo-500 hover:text-indigo-600 duration-75"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                opacity="0.2"
                d="M12 22.01C17.5228 22.01 22 17.5329 22 12.01C22 6.48716 17.5228 2.01001 12 2.01001C6.47715 2.01001 2 6.48716 2 12.01C2 17.5329 6.47715 22.01 12 22.01Z"
                fill="currentColor"
              ></path>{" "}
              <path
                d="M12 6.93994C9.93 6.93994 8.25 8.61994 8.25 10.6899C8.25 12.7199 9.84 14.3699 11.95 14.4299C11.98 14.4299 12.02 14.4299 12.04 14.4299C12.06 14.4299 12.09 14.4299 12.11 14.4299C12.12 14.4299 12.13 14.4299 12.13 14.4299C14.15 14.3599 15.74 12.7199 15.75 10.6899C15.75 8.61994 14.07 6.93994 12 6.93994Z"
                fill="currentColor"
              ></path>{" "}
              <path
                d="M18.7807 19.36C17.0007 21 14.6207 22.01 12.0007 22.01C9.3807 22.01 7.0007 21 5.2207 19.36C5.4607 18.45 6.1107 17.62 7.0607 16.98C9.7907 15.16 14.2307 15.16 16.9407 16.98C17.9007 17.62 18.5407 18.45 18.7807 19.36Z"
                fill="currentColor"
              ></path>{" "}
            </g>
          </svg>}
      </a>
    </div >
  );
}
