export function getQueryParams(location: any) {
  function sanitizeText(str: string | null): string | null {
    if (typeof str !== "string" || !str) return null;
    return str.replace(/<[^>]*>?/gm, "");
  }

  const params = new URLSearchParams(location.search);

  // @ts-ignore
  const paramsValues = [...params.entries()]
  const errorContent = paramsValues.find(v => v.length > 1 && v[0] === "ccm-error")
  const successContent = paramsValues.find(v => v.length > 1 && v[0] === "ccm-success")

  const error = errorContent ? sanitizeText(decodeURI(errorContent[1])) : null;
  const success = successContent ? sanitizeText(decodeURI(successContent[1])) : null;

  return { error, success };
}

export function getProductSearchParams(location: any) {


  const params = new URLSearchParams(location.search);
  const searchBody = {}

  // @ts-ignore
  const paramsValues = [...params.entries()]

  // eslint-disable-next-line array-callback-return
  paramsValues.map((value) => {
    // @ts-ignore
    searchBody[value[0]] = value[1]
  })

  return searchBody;
}