import { useState } from "react";
import { RequiredTick } from "../../../../Customization/RequiredTick";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface EditingFileProductProps {
  content: {
    productId: number;
    productImg: string;
    productType: string;
    productName: string;
    productDescription: string;
    productPrice: number;
    productOriginalPrice: number;
    productStock: number;
    productUrl: string;
  };
  closeModal: () => void;
}

export default function EditThirdPartyProduct({
  content,
  closeModal,
}: EditingFileProductProps) {
  const [filename, setFilename] = useState<string>("");
  const [imagePreviewSrc, setImagePreviewSrc] = useState<string>("");
  const [isEventListenerAdded, setIsEventListenerAdded] =
    useState<boolean>(false);
  const [stock, setStock] = useState<any>(1);
  const [unlimitedStock, setUnlimitedStock] = useState<boolean>(false);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFilename(file.name);

      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreviewSrc(e.target?.result as string);
      };
      reader.readAsDataURL(file);

      if (!isEventListenerAdded) {
        setIsEventListenerAdded(true);
      }
    } else {
      setFilename("");
      setImagePreviewSrc("");

      if (isEventListenerAdded) {
        setIsEventListenerAdded(false);
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!filename || filename === "") {
      toast.error("Please select a product image.");
      return;
    }
  };

  return (
    <form method="post" action="#" encType="multipart/form-data" onSubmit={handleSubmit}>
      <div className="grid gap-4 grid-cols-1">
        <div className="col-span-1 grid gap-4 mb-4 grid-cols-2">
          <div className="col-span-2">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Name
              <RequiredTick />
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5"
              placeholder="Type product name"
              value={content.productName}
              required={true}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <label
              htmlFor="price"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Price
              <RequiredTick />
            </label>
            <input
              type="number"
              name="price"
              id="price"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5"
              placeholder="$19.99"
              value={content.productPrice}
              required={true}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <label
              htmlFor="price"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Original price
              <RequiredTick />
            </label>
            <input
              type="number"
              name="original-price"
              id="original-price"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5"
              placeholder="$30.00"
              value={content.productOriginalPrice}
              required={true}
            />
          </div>
          <div className="col-span-2">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Stock
              <RequiredTick />
            </label>
            <input
              id="stock"
              required={!unlimitedStock}
              disabled={unlimitedStock}
              name="stock"
              type="number"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              className={`bg-gray-50 text-gray-900 border border-gray-300 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5 disabled:bg-gray-200/90 disabled:text-gray-500 disabled:cursor-not-allowed`}
            ></input>
            <div className="flex items-center mt-2">
              <label
                htmlFor="unlimited-stock"
                className="block text-sm font-medium text-gray-900"
              >
                Unlimited stock?
              </label>
              <input
                type="checkbox"
                id="unlimited-stock"
                name="unlimited-stock"
                className="ml-2 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                checked={unlimitedStock}
                onChange={(e) => setUnlimitedStock(e.target.checked)}
              />
            </div>
          </div>
          <div className="col-span-2">
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Product Description
              <RequiredTick />
            </label>
            <textarea
              id="description"
              rows={4}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              placeholder="Write product description here"
              value={content.productDescription}
            ></textarea>
          </div>
          <div className="col-span-2 flex flex-col gap-1">
            <span className="block mb-1 text-sm font-medium text-gray-900">
              Product photo
              <RequiredTick />
            </span>
            <div
              id="image-preview"
              className={`w-full px-3 py-6 bg-gray-50 hover:bg-gray-100 duration-100 rounded-lg items-center mx-auto text-center cursor-pointer ${
                !imagePreviewSrc && "border-dashed border-2 border-gray-400"
              }`}
              onClick={() => document.getElementById("upload")?.click()}
            >
              {imagePreviewSrc && (
                <img
                  src={imagePreviewSrc}
                  className="max-h-48 rounded-lg mx-auto mb-2"
                  alt="Product Preview"
                />
              )}
              <input
                id="upload"
                type="file"
                className="hidden"
                name="productImage"
                accept="image/png, image/jpeg"
                onChange={handleImageChange}
              />
              <label htmlFor="upload" className="cursor-pointer">
                <div className={`${imagePreviewSrc && "hidden"}`}>
                  <div className="flex flex-row gap-3 justify-center items-center">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      height="40px"
                      width="40px"
                      className="text-indigo-400"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M14.2647 15.9377L12.5473 14.2346C11.758 13.4519 11.3633 13.0605 10.9089 12.9137C10.5092 12.7845 10.079 12.7845 9.67922 12.9137C9.22485 13.0605 8.83017 13.4519 8.04082 14.2346L4.04193 18.2622M14.2647 15.9377L14.606 15.5991C15.412 14.7999 15.8149 14.4003 16.2773 14.2545C16.6839 14.1262 17.1208 14.1312 17.5244 14.2688C17.9832 14.4253 18.3769 14.834 19.1642 15.6515L20 16.5001M14.2647 15.9377L18.22 19.9628M18.22 19.9628C17.8703 20 17.4213 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.7157 19.5903 4.40973 19.2843 4.21799 18.908C4.12583 18.7271 4.07264 18.5226 4.04193 18.2622M18.22 19.9628C18.5007 19.9329 18.7175 19.8791 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V13M11 4H7.2C6.07989 4 5.51984 4 5.09202 4.21799C4.7157 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.0799 4 7.2V16.8C4 17.4466 4 17.9066 4.04193 18.2622M18 9V6M18 6V3M18 6H21M18 6H15"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>{" "}
                      </g>
                    </svg>

                    <h5 className="text-xl font-bold tracking-tight text-gray-700">
                      Upload product photo
                    </h5>
                  </div>
                  <span className="block font-normal text-sm text-gray-400 md:px-6">
                    Choose image size should be less than{" "}
                    <b className="text-gray-600">6mb</b>
                  </span>
                  <span className="block font-normal text-sm text-gray-400 md:px-6">
                    File should be in <b className="text-gray-600">.png</b> or{" "}
                    <b className="text-gray-600">.jpg</b> format.
                  </span>
                </div>

                <span id="filename" className="text-gray-500 bg-gray-200 z-50">
                  {filename}
                </span>
              </label>
            </div>
          </div>
          <div className="col-span-2">
            <label
              htmlFor="websiteUrl"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Website URL
              <RequiredTick />
            </label>
            <input
              id="websiteUrl"
              type="url"
              name="productUrl"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5"
              placeholder="Paste your product URL here..."
              value={content.productUrl}
              required={true}
            ></input>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row gap-3 max-sm:flex-col justify-end items-center">
        <button
          type="button"
          onClick={closeModal}
          className="text-white inline-flex items-center bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          <svg
            className="me-1 -ms-1 w-5 h-5 rotate-45"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          Cancel
        </button>

        <button
          type="submit"
          className="text-white inline-flex items-center bg-yellow-400 hover:bg-yellow-400/80 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          <svg
            className="me-1 -ms-1 w-5 h-5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <title></title>{" "}
              <g id="Complete">
                {" "}
                <g id="edit">
                  {" "}
                  <g>
                    {" "}
                    <path
                      d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>{" "}
                    <polygon
                      fill="none"
                      points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></polygon>{" "}
                  </g>{" "}
                </g>{" "}
              </g>{" "}
            </g>
          </svg>
          Edit product
        </button>
      </div>
    </form>
  );
}
