import React from 'react';

interface ErrorProps {
  errorCode: any,
  errorMessage: string
}

export function ErrorPage({errorCode, errorMessage}: ErrorProps) {
  return (
    <section className="flex items-center justify-center w-screen h-screen bg-gray-100">
      <div className="flex flex-col items-center bg-gray-50 p-12 shadow-lg rounded-lg">
        <div className="flex flex-col gap-6 max-w-md text-center">
          <h2 className="font-bold text-5xl text-indigo-500">
            <span className="sr-only">Error {errorCode}</span>Error {errorCode}
          </h2>
          <p className="text-xl text-gray-600">
            {errorMessage}
          </p>
          <a
            href="/"
            className="py-3 text-xl font-semibold rounded transition-background duration-100 bg-indigo-400 hover:bg-indigo-500 text-white shadow-lg"
          >
            Back to home
          </a>
        </div>
      </div>
    </section>
  );
}
