import { ProductCard } from "./Cards/ProductCard";

export function Wishlist() {
  return (
    <div
      className="flex-1 flex flex-col gap-6 bg-white shadow-md xl:max-w-[80%] rounded-lg"
      id="wishlist"
    >
      <div className="flex items-end flex-col justify-end">
        <div className="w-full lg:px-8 py-14 md:px-6 px-4 bg-white max-md:text-center">
          <p className="text-3xl font-semibold text-gray-800 px-9">
            Your wishlist
          </p>
          <div className="pt-6">
            <div className="flex flex-row items-center mb-6 md:mb-8 px-6">
              <div className="flex-1 px-4">
                <h2 className="font-bold text-gray-500">Product details</h2>
              </div>
              <div className="flex-1 px-4 max-md:hidden">
                <h2 className="font-bold text-gray-500">Name</h2>
              </div>
              <div className="flex-1 px-4 max-md:hidden">
                <h2 className="font-bold text-gray-500">Price</h2>
              </div>
              <div className="flex-1 px-4 max-md:hidden text-center">
                <h2 className="font-bold text-gray-500">Actions</h2>
              </div>
            </div>
            <div className="py-4 mb-8 border-y border-gray-200 flex max-md:flex-row max-md:flex-wrap flex-col gap-5">
              <ProductCard></ProductCard>
              <ProductCard></ProductCard>
              <ProductCard></ProductCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
