"use client";

import { Splide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import { SliderArrow } from "./SliderArrow";
import Slide from "./Slide";

//@ts-ignore
import defaultProductImage from "../../../assets/products/defaultProduct.webp"
import { useEffect, useState } from "react";

interface IProductSlider {
  featuredTags: Array<string>,
  image:
  defaultProductImage,
  providers: string,
  name: string,
  description: string,
  price: number,
  stars: number,
  author: string,
  sales: number,
  uuid: string,
  type: string
}

export default function Slider() {

  const [products, setProducts] = useState(new Array<IProductSlider>());

  useEffect(() => {
    (async () => {
      const data = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/products/get?featuredTags=trending")
      const json = await data.json()

      const _products = await Promise.all(json.body.products.map(async (_product: any) => {
        const imageData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/images/get/" + _product.image)
        const image = await imageData.json()
        _product.image = image.status === "success" ? image.body.base64 : defaultProductImage
        return _product
      }))
      setProducts(_products)
    })()

  }, [])

  return (
    <Splide
      id="SplideContainer"
      options={{
        type: "loop",
        perPage: 4,
        speed: 1200,
        autoplay: true,
        mediaQuery: "min",
        breakpoints: {
          1024: {
            perPage: 4,
            speed: 500,
            easing: "ease-in-out",
          },
          767: {
            perPage: 3,
            speed: 500,
            easing: "ease-in-out",
          },
          640: {
            perPage: 2,
            speed: 500,
            easing: "ease-in-out",
          },
        },
        focus: "center",
        gap: "1.5rem",
        drag: false,
        easing: "ease-in-out",
        pagination: false,
      }}
      hasTrack={false}
      className="mx-auto container overflow-y-hidden"
    >
      <div className="absolute bg-[linear-gradient(to_left,_rgba(255,255,255,0)_0%,_#fff_100%)] left-0 h-full w-20 z-20"></div>
      <div className="absolute bg-[linear-gradient(to_right,_rgba(255,255,255,0)_0%,_#fff_100%)] right-0 h-full w-20 z-20"></div>

      <div className="splide__arrows">
        <button className="splide__arrow splide__arrow--prev bg-transparent text-black !z-50">
          <SliderArrow></SliderArrow>
        </button>
        <button className="splide__arrow splide__arrow--next bg-transparent text-black !z-50">
          <SliderArrow></SliderArrow>
        </button>
      </div>

      <SplideTrack className="w-full relative !px-8">
        {products.map((slide, index) => (
          <Slide
            key={index}
            featuredTags={slide.featuredTags}
            image={slide.image}
            providers={slide.providers}
            name={slide.name}
            description={slide.description}
            price={slide.price}
            stars={slide.stars}
            author={slide.author}
            sales={slide.sales}
            uuid={slide.uuid}
            pType={slide.type}
          ></Slide>
        ))}
      </SplideTrack>
    </Splide>
  );
}
