import { IProduct } from "./Product.js"

export interface IProductToWishlist {
  amount: number
  product: IProduct
}

export interface IWishlist {
  userUUID: string;
  uuid: string;
  products: Array<IProductToWishlist>;
}

export class Wishlist implements IWishlist {
  userUUID: string = "";
  uuid: string = "";
  products: IProductToWishlist[] = new Array<IProductToWishlist>();

}


