export const navbarItems = [
  { title: "Home", link: "/" },
  {
    title: "All resources",
    link: "/marketplace?category=all",
  },
  {
    title: "Minecraft",
    link: "/marketplace?category=minecraftJava",
  },
  {
    title: "Third Party",
    link: "/marketplace?category=thirdParty",
  },
  {
    title: "Roblox",
    link: "/marketplace?category=roblox",
  },
  { title: "Support", link: "/support" },
];

/* 
submenu: [
  { title: "All categories", link: "/marketplace?q=all" },
  { title: "Minecraft java", link: "/marketplace?q=java" },
  { title: "Minecraft bedrock", link: "/marketplace?q=bedrock" },
  { title: "Spigot", link: "/marketplace?q=spigot" },
  { title: "Bukkit", link: "/marketplace?q=bukkit" },
  { title: "Paper", link: "/marketplace?q=paper" },
  { title: "Forge", link: "/marketplace?q=forge" },
]
*/
