import { toast } from "react-toastify";
//@ts-ignore
import productLogo from "../assets/products/defaultProduct.webp"

export async function createProductRequest(event: React.FormEvent<HTMLFormElement>, closeModal: Function, type: string, setProducts: Function) {
  const formToSend = new FormData()


  //@ts-ignore
  let pending = [...event.target].filter((element: any) => (element.tagName.toLowerCase() === "input" || element.tagName.toLowerCase() === "select" || element.tagName.toLowerCase() === "textarea") && element.type.toLowerCase() !== "submit").length;
  //@ts-ignore
  [...event.target].filter((element: any) => (element.tagName.toLowerCase() === "input" || element.tagName.toLowerCase() === "select" || element.tagName.toLowerCase() === "textarea") && element.type.toLowerCase() !== "submit").forEach(element => {

    if ((element.tagName.toLowerCase() === "input" && element.type.toLowerCase() === "file") && element.type.toLowerCase() !== "submit") {
      var reader = new FileReader();
      reader.onloadend = function () {
        pending--
        //@ts-ignore
        formToSend.append(element.name || element.id, new Blob([reader.result]))

        if (pending === 0) {
          fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/products/create/" + type, {
            method: "POST",
            body: formToSend,

          }).then((res) => res.json().then((data) => {
            if (data.status !== "success") {
              toast.error(data.message);
              return;
            }
            closeModal()
            toast.success(data.message);
            updateProductsView(setProducts)
            return;
          }))
        }
      }
      reader.readAsArrayBuffer(element.files[0]);

    } else {
      pending--
      formToSend.append(element.name || element.id, element.value || element.content)
    }
  });

}

export async function updateProductsView(setProducts: Function) {

  const userData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/profile/getInfo")
  const userJSON = await userData.json();


  const data = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/products/get?author=" + userJSON.body.username + "&approved=all")
  const json = await data.json()

  const _products = await Promise.all(json.body.products.map(async (_product: any) => {
    const imageData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/images/get/" + _product.image)
    const image = await imageData.json()
    _product.productId = _product.uuid
    _product.productType = _product.type
    _product.productName = _product.name
    _product.productDescription = _product.description
    _product.productPrice = _product.price
    _product.productOriginalPrice = _product.price
    _product.productStock = _product.stock
    _product.productService = _product.completeDescription
    _product.productImg = image.status === "success" ? image.body.base64 : productLogo
    return _product
  }))
  setProducts(_products)

}
