"use client";

import Stars from "../../Customization/Stars";
import { SplideSlide } from "@splidejs/react-splide";

interface SlideProps {
  featuredTags: string[];
  image: string;
  providers: string;
  name: string;
  description: string;
  price: number;
  stars: number;
  author: string;
  sales: number;
  uuid: string;
  pType: string;
}

const Slide: React.FC<SlideProps> = ({
  featuredTags,
  image,
  providers,
  name,
  description,
  price,
  stars,
  author,
  sales,
  uuid,
  pType
}) => {
  return (
    <SplideSlide className="flex flex-col overflow-hidden rounded-lg shadow-lg bg-white min-w-[250px] sm:min-w-[300px] md:min-w-[340px]">
      <div className="w-full p-5 flex gap-2 max-sm:justify-center">
        {featuredTags.map((tag, index) => (
          <div
            key={index}
            className={`w-fit px-3 py-1 resourceTag_${tag} rounded-full font-semibold capitalize`}
          >
            {tag}
          </div>
        ))}
      </div>
      <div className="flex-shrink-0 px-5">
        <img
          className="h-48 w-full object-cover rounded-lg shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]"
          src={image}
          alt={`${name}`}
        />
      </div>
      <div className="flex flex-1 flex-col justify-between p-5">
        <div className="flex-1">
          <p className="text-sm font-medium text-[#d07a19]">
            <a href="/" className="hover:underline">
              {providers}
            </a>
          </p>
          <div className="mt-2 block">
            <div className="flex justify-between">
              <a
                href={`/marketplace/product/${uuid
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`}
                className="text-xl font-semibold text-gray-900 hover:underline"
              >
                {name}
              </a>
              <Stars value={stars}></Stars>
            </div>

            <p className="mt-3 text-base text-gray-500">
              {description.slice(0, 100)}...
            </p>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-between">
          <div className="flex-shrink-0">
            <span className="text-green-500 text-xl font-semibold">
              {price > 0 ? `$${price}` : "Free"}
            </span>
          </div>
          <div className="ml-3 text-right">
            <p className="text-sm font-medium text-gray-900">
              <a
                href={`/marketplace/authors/${author
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`}
                className="hover:underline"
              >
                {author}
              </a>
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              {pType === "third_party" ? <span>{sales.toString()} views</span> : <span>{sales.toString()} purchases</span>}
            </div>
          </div>
        </div>
      </div>
    </SplideSlide>
  );
};

export default Slide;
