import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//@ts-ignore
import defaultProductImage from "../../../assets/products/defaultProduct.webp"
import CategoryCheckBox from "./CategoryCheckBox";


export default function MarketSidebar() {

  const [productsData, setProductsData] = useState([
    {
      featuredTags: ["trending", "promoted"],
      image:
        defaultProductImage,
      providers: "Cloudy Coding",
      name: "Cloudy Coding",
      description:
        "Default product render",
      price: 299.99,
      stars: 5,
      author: "CloudyCoding",
      sales: 399,
      uuid: "CloudyCoding",
      offer: 0
    }
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [options, setOptions] = useState([
    {
      value: "all",
      label: "All categories",
      defaultInputValue: true,
    }
  ])


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  //@ts-ignore
  const entries = [...searchParams.entries()]
  const search = entries.map(entry => {
    return `${entry[0]}=${entry[1]}`
  }).join("&")

  useEffect(() => {
    (async () => {
      const data = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/products/get?" + search)
      const json = await data.json()

      const _products = await Promise.all(json.body.products.map(async (_product: any) => {
        const imageData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/images/get/" + _product.image)
        const image = await imageData.json()
        _product.image = image.status === "success" ? image.body.base64 : defaultProductImage
        return _product
      }))
      setProductsData(_products)

      const statsData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/stats/get/all")
      const stats = await statsData.json()
      const concatStats = [
        {
          value: "all",
          label: "All categories",
          defaultInputValue: true,
        }
      ].concat(...stats.body.categories.map((v: { label: any; key: any; }) => {
        v.label = v.key
        return v
      }))

      setOptions(concatStats)
    })()
  }, [])

  return (
    <aside className="flex-1 h-fit py-6 bg-gray-50 rounded-lg max-xl:w-full xl:max-w-[20%] border border-gray-200">
      <div className="flex flex-col gap-3 px-6">
        <div>
          <h2 className="mb-1 text-lg font-semibold text-gray-700">
            All products
          </h2>
          <ul className="max-w-md space-y-1 list-none list-inside text-gray-600 ms-3">
            <li>
              <span className="font-semibold">{productsData.length}</span> Products
            </li>
          </ul>
        </div>
        <form action="/marketplace" method="get" className="flex flex-col gap-3">
          <div>
            <h3 className="mb-1 font-semibold text-gray-900">Offers</h3>
            <ul className="w-full text-sm font-medium text-gray-900 flex flex-col">
              <li className="w-full rounded-t-lg">
                <div className="flex items-center ps-3">
                  <input
                    id="only-offers-filter"
                    type="checkbox"
                    value="true"
                    name="only-offers-filter"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label
                    htmlFor="only-offers-filter"
                    className="w-full py-1 ms-2 text-sm font-medium text-gray-900"
                  >
                    Only offers
                  </label>
                </div>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="mb-1 font-semibold text-gray-900">Categories</h3>
            <ul className="w-full text-sm font-medium text-gray-900 flex flex-col">
              {options.map((option) => (
                <CategoryCheckBox label={option.label} value={option.value}></CategoryCheckBox>
              ))}

            </ul>
          </div>

          <div>
            <h3 className="mb-1 font-semibold text-gray-900">Price</h3>
            <ul className="w-full text-sm font-medium text-gray-900 flex flex-col">
              <li className="w-full rounded-t-lg">
                <div className="flex items-center flex-wrap ps-3">
                  <span className="w-full py-1 text-sm font-medium text-gray-900">
                    Select price range:
                  </span>
                  <div className="flex flex-wrap gap-2">
                    <input
                      id="min-price-filter"
                      type="number"
                      defaultValue={0}
                      placeholder="Min price..."
                      name="min-price-filter"
                      className="w-24 px-1 text-gray-500 bg-gray-200 border-gray-500 rounded focus:ring-blue-500"
                    />
                    to
                    <input
                      id="max-price-filter"
                      type="number"
                      placeholder="Max price..."
                      name="max-price-filter"
                      className="w-24 px-1 text-gray-500 bg-gray-200 border-gray-500 rounded focus:ring-blue-500"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <button className="mt-2 w-full bg-indigo-500 py-2 font-semibold text-white rounded-lg">
            Apply filters
          </button>
        </form>
      </div>
    </aside>
  );
}
