
export function ProfileData({ imgSrc, profileName, profileEmail }: any) {
  return (
    <div className="w-full relative flex max-sm:flex-col max-sm:gap-3 justify-between items-center">
      <div className="flex justify-start items-center gap-3 max-sm:flex-col max-sm:text-center">
        <div>
          <img
            className="w-12 h-12 rounded-full border border-gray-100 shadow-sm"
            src={imgSrc}
            alt={profileName}
          />
        </div>
        <div className="flex-1">
          <div>
            <span className="font-semibold text-gray-800">{profileName}</span>
          </div>
          <div className="font-normal">
            <span className="text-gray-600">{profileEmail}</span>
          </div>
        </div>
      </div>
      <a className="max-sm:w-full" href={process.env.REACT_APP_API_WEB_BASE_URL + "/auth/logout"}>
        <button className="bg-red-400 px-3.5 py-1.5 rounded-lg text-white font-semibold max-sm:w-full duration-100 hover:bg-red-500">
          Log out
        </button>
      </a>
    </div>
  );
}
