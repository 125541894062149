import { useEffect, useState } from "react";
import { CheckoutCard } from "./Cards/CheckoutCard";
import ProductCard from "./Cards/ProductCard";
//@ts-ignore
import img from "../../../assets/products/defaultProduct.webp"

export function Cart() {

  const [products, setProducts] = useState([{
    amount: 1,
    product: {
      author: "CloudyCoding",
      image: img,
      name: "Cloudy Coding",
      offer: 10,
      price: 4.99,
      uuid: "cloudyCoding",
      stock: 10,
      unlimited: true
    }
  }])

  useEffect(() => {
    (async () => {
      const data = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/profile/getCart")
      const json = await data.json()
      // setProducts(json.body.products)
    })()
  }, [])

  return (
    <div
      className="flex-1 flex flex-col gap-6 bg-white shadow-md xl:max-w-[80%] rounded-lg"
      id="cart"
    >
      <div className="flex items-end flex-col justify-end">
        <div className="w-full lg:px-8 py-14 md:px-6 px-4 bg-white max-md:text-center">
          <p className="text-3xl font-semibold text-gray-800 px-9">Your cart</p>
          <div className="pt-6">
            <div className="flex flex-row items-center mb-6 md:mb-8 px-6">
              <div className="flex-1 px-4">
                <h2 className="font-bold text-gray-500">Product details</h2>
              </div>
              <div className="flex-1 px-4 max-md:hidden">
                <h2 className="font-bold text-gray-500">Name</h2>
              </div>
              <div className="flex-1 px-4 max-md:hidden ">
                <h2 className="font-bold text-gray-500">Price</h2>
              </div>
              <div className="flex-1 px-4 max-md:hidden ">
                <h2 className="font-bold text-gray-500">Quantity</h2>
              </div>
              <div className="flex-1 px-4 max-md:hidden  text-right">
                <h2 className="font-bold text-gray-500"> Subtotal</h2>
              </div>
            </div>
            <div className="py-4 mb-8 border-y border-gray-200 flex max-md:flex-row max-md:flex-wrap flex-col gap-5">
              {products.map(product => (
                <ProductCard
                  author={product.product.author}
                  image={product.product.image}
                  name={product.product.name}
                  offer={product.product.offer}
                  price={product.product.price}
                  uuid={product.product.uuid}
                  stock={product.product.stock}
                  unlimited={product.product.unlimited}
                  amount={product.amount}>
                </ProductCard>
              ))}

            </div>
          </div>
        </div>
        <CheckoutCard></CheckoutCard>
      </div>
    </div>
  );
}
