import { useEffect, useState } from "react";

export function CheckoutCard() {


  const [subTotal, setSubTotal] = useState(0);

  const [products, setProducts] = useState(0)

  useEffect(() => {
    (async () => {
      const data = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/profile/getCart")
      const json = await data.json()
      setProducts(json.body.products.length)
    })()
  }, [])


  setInterval(() => {
    updateSubTotal()
  }, 100)

  function updateSubTotal() {
    //@ts-ignore
    const price = [...document.getElementsByClassName("totalPrice")].reduce((acc, item) => {
      return acc + Number(item.textContent?.replace("$", ""))
    }, 0)
    //@ts-ignore
    setSubTotal(price.toFixed(2))
  }

  return (
    <div className="w-full bg-gray-100">
      <form className="flex flex-col h-fit p-8 justify-between overflow-y-auto">
        <div>
          <p className="text-3xl font-semibold leading-9 text-gray-800">
            Revision
          </p>
          <div className="flex items-center justify-between pt-6">
            <p className="text-base leading-none text-gray-800">Subtotal</p>
            <p className="text-base leading-none text-gray-800">${subTotal}</p>
          </div>
          <div className="flex items-center justify-between pt-5">
            <p className="text-base leading-none text-gray-800">Platform fixed fee</p>
            <p className="text-base leading-none text-gray-800">
              {products > 0 ? "$0.65" : "$0"}
            </p>
          </div>
          <div className="flex items-center justify-between pt-5">
            <p className="text-base leading-none text-gray-800">Platform use fee ( 3% )</p>

            <p className="text-base leading-none text-gray-800">${(subTotal * 0.03).toFixed(2)}</p>
          </div>
        </div>
        <div>
          <div className="flex items-center pb-6 justify-between pt-6">
            <p className="text-2xl leading-normal text-gray-800">Total</p>
            <p className="text-2xl font-bold leading-normal text-right text-gray-800">
              ${Number(Number(subTotal) + (products > 0 ? 0.65 : 0) + Number((subTotal * 0.03).toFixed(2))).toFixed(2)}
            </p>
          </div>
          <button className="text-base font-semibold leading-none w-full py-5 bg-indigo-400 hover:bg-indigo-500 duration-100 rounded-lg focus:outline-none text-white">
            Buy now
          </button>
        </div>
      </form>
    </div>
  );
}
