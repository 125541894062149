import ReCAPTCHA from "react-google-recaptcha";
//@ts-ignore
import logo from "../../assets/logos/logo.webp";
import { getQueryParams } from "../../utils/getQueryParams";
import { useLocation } from "react-router-dom";

import RenderNotification from "../Notifications/RenderNotification";
import ErrorBoundary from "./Errors/ErrorBoundary";
import { FormEvent, useEffect } from "react";
import { postRequest } from "../../utils/postRequest";
import secureLocalStorage from "react-secure-storage";
import { NeedUnprotected } from "../Protections/needUnprotected"

export default function Signin() {
  const location = useLocation();
  const { error, success } = getQueryParams(location);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    //@ts-ignore
    const values = [...event.currentTarget.getElementsByTagName("input")].concat(...event.currentTarget.getElementsByTagName("textarea")) as Array<HTMLInputElement>
    const json = {}
    values.forEach(value => {
      //@ts-ignore
      json[value.name] = value.value
    })

    const data = await postRequest(process.env.REACT_APP_API_WEB_BASE_URL + "/auth/login/cloudycoding", json)
    secureLocalStorage.setItem("authorization", data.body)
  }

  return (
    <ErrorBoundary>

      <NeedUnprotected></NeedUnprotected>
      <RenderNotification error={error} success={success} />
      <div className="min-h-screen bg-gray-100 bg-line-theme bg-center bg-cover text-gray-900 flex justify-center">
        <div className="w-full m-0 bg-white shadow-lg sm:rounded-lg flex flex-row justify-center flex-1">
          <div className="w-full lg:w-1/2 max-sm:p-6 flex items-center justify-center">
            <div className="w-full flex flex-col items-center justify-center">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600 max-w">
                  Or{" "}
                  <a
                    href="/signup"
                    className="font-medium transition-color duration-200 text-indigo-600 hover:text-indigo-400"
                  >
                    create an account
                  </a>
                </p>
              </div>

              <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                  <form
                    className="space-y-6"
                    action={
                      process.env.REACT_APP_API_WEB_BASE_URL + "/auth/login/cloudycoding"
                    }
                    method="POST"
                    onSubmit={handleSubmit}
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          placeholder="Enter your email address"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          placeholder="Enter your password"
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <a
                        href="/tos"
                        className="text-sm font-semibold transition duration-100 text-indigo-600 hover:text-indigo-400"
                      >
                        Forgot your password?
                      </a>
                    </div>

                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY + ""}
                    />

                    <div>
                      <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>

                  {/* <div className="mt-6">
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300"></div>
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-gray-100 text-gray-500">
                          Or continue with
                        </span>
                      </div>
                    </div>

                    <div className="mt-6 flex flex-wrap gap-3">
                      <a
                        href="/"
                        className="flex-1 flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                      >
                        <img
                          className="h-5 w-5"
                          src="https://www.svgrepo.com/show/353655/discord-icon.svg"
                          alt="Continue with discord"
                        />
                      </a>
                      <a
                        href="/"
                        className="flex-1 flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                      >
                        <img
                          className="h-5 w-5"
                          src="https://www.svgrepo.com/show/475654/github-color.svg"
                          alt="Continue with Github"
                        />
                      </a>
                      <a
                        href="/"
                        className="flex-1 flex items-center justify-center px-8 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                      >
                        <img
                          className="h-6 w-6"
                          src="https://www.svgrepo.com/show/452216/google.svg"
                          alt="Continue with google"
                        />
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 relative bg-gradient-to-b from-indigo-500 to-indigo-100 text-center hidden lg:flex items-center justify-center overflow-hidden">
            <div className="absolute h-full w-full bg-cloud-theme bg-cover bg-bottom bg-no-repeat"></div>
            <img
              className="h-64 z-20 animate-smoothwiggle"
              src={logo}
              alt="Cloudy Coding logo"
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}
