import React from "react";
import "./index.css";
import App from "./App";

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Screens
import Signin from "./components/Screens/Signin";
import Signup from "./components/Screens/Signup";
import Marketplace from "./components/Screens/Market/Market";
import MarketProduct from "./components/Screens/Market/Product";
import Profile from "./components/Screens/Profile/Profile";
import ShoppingCart from "./components/Screens/Profile/ShoppingCart";
import WishList from "./components/Screens/Profile/WishList";
import Products from "./components/Screens/Profile/Products";
import ManageData from "./components/Screens/Profile/ManageData";
import ResetPassword from "./components/Screens/Profile/ResetPassword";

import { ErrorPage } from "./components/Screens/Errors/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "marketplace",
    element: <Marketplace />,
  },
  {
    path: "marketplace/product/:id",
    element: <MarketProduct />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "/profile/manage",
    element: <ManageData />,
  },
  {
    path: "/profile/reset-password",
    element: <ResetPassword />
  },
  {
    path: "cart",
    element: <ShoppingCart />,
  },
  {
    path: "wishlist",
    element: <WishList />,
  },
  {
    path: "/profile/products",
    element: <Products />,
  },
  {
    path: "signin",
    element: <Signin />,
  },
  {
    path: "signup",
    element: <Signup />,
  },
  {
    path: "*",
    element: (
      <ErrorPage
        errorCode="404"
        errorMessage="The page you are looking for might have been removed, had its name
        changed or is temporarily unavailable."
      />
    ),
  },
]);

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<RouterProvider router={router} />);
