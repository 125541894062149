export default function Trust() {
  return (
    <section className="bg-gray-100 py-16 text-gray-700">
      <div className="container mx-auto max-sm:px-4">
        <h2 className="text-3xl font-bold text-center mb-8">
          Why you should <span className="text-indigo-500">trust</span>{" "}
          <span className="text-indigo-400">us</span>?
        </h2>
        <div className="w-full flex flex-row flex-wrap justify-center items-center gap-8">
          <div className="min-w-[240px] flex-1 flex flex-col gap-3 justify-center items-center text-center p-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64px"
              height="64px"
              viewBox="0 0 24 24"
              className="text-indigo-400"
              fill="none"
            >
              <path
                d="M11 21H5.6C5.03995 21 4.75992 21 4.54601 20.891C4.35785 20.7951 4.20487 20.6422 4.10899 20.454C4 20.2401 4 19.9601 4 19.4V17.6841C4 17.0485 4 16.7306 4.04798 16.4656C4.27087 15.2344 5.23442 14.2709 6.46558 14.048C6.5425 14.0341 6.6237 14.0242 6.71575 14.0172C6.94079 14 7.05331 13.9914 7.20361 14.0026C7.35983 14.0143 7.4472 14.0297 7.59797 14.0722C7.74302 14.1131 8.00429 14.2315 8.52682 14.4682C8.98953 14.6778 9.48358 14.8304 10 14.917M19.8726 15.2038C19.8044 15.2079 19.7357 15.21 19.6667 15.21C18.6422 15.21 17.7077 14.7524 17 14C16.2923 14.7524 15.3578 15.2099 14.3333 15.2099C14.2643 15.2099 14.1956 15.2078 14.1274 15.2037C14.0442 15.5853 14 15.9855 14 16.3979C14 18.6121 15.2748 20.4725 17 21C18.7252 20.4725 20 18.6121 20 16.3979C20 15.9855 19.9558 15.5853 19.8726 15.2038ZM15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex flex-col gap-2">
              <h2 className="text-2xl font-semibold">Buyer Protection Guarantee</h2>
              <p>
                Shop confidently with our Buyer Protection Guarantee. Get refunds for misrepresented items. Your satisfaction is our priority.
              </p>
            </div>
          </div>
          <div className="min-w-[240px] flex-1 flex flex-col gap-3 justify-center items-center text-center p-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64px"
              height="64px"
              viewBox="0 0 24 24"
              className="text-indigo-400"
              fill="none"
            >
              <path
                d="M20 6C20 6 19.1843 6 19.0001 6C16.2681 6 13.8871 4.93485 11.9999 3C10.1128 4.93478 7.73199 6 5.00009 6C4.81589 6 4.00009 6 4.00009 6C4.00009 6 4 8 4 9.16611C4 14.8596 7.3994 19.6436 12 21C16.6006 19.6436 20 14.8596 20 9.16611C20 8 20 6 20 6Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex flex-col gap-2">
              <h2 className="text-2xl font-semibold">Secure Shopping and Data Protection</h2>
              <p>
                Enjoy peace of mind when shopping with us. Your information is secure and backed by our commitment to data protection. Shop with confidence and peace of mind.
              </p>
            </div>
          </div>
          <div className="min-w-[240px] flex-1 flex flex-col gap-3 justify-center items-center text-center p-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64px"
              height="64px"
              viewBox="0 0 24 24"
              className="text-indigo-400"
              fill="none"
            >
              <rect
                x="3"
                y="6"
                width="18"
                height="13"
                rx="2"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 10H20.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 15H9"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex flex-col gap-2">
              <h2 className="text-2xl font-semibold">Wide Range of Payment Methods and Currency Exchange</h2>
              <p className="text-md">
                We offer diverse payment options and currency exchange services for your convenience. Shop globally with ease, knowing you can pay in your preferred currency. Explore our flexible payment solutions today.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
