
export interface IStat {
  value: string
  key: string
}

export interface IAutoCompleteKeyword {
  id: string
  text: string
}

export interface IAutoCompleteTag {
  id: string
  text: string
}


export interface IGlobalStats {
  totalSales: number
  resourcesAuthor: number
  totalResources: number
  positiveReviews: number
  categories: Array<IStat>
  autoCompleteKeywords: Array<IAutoCompleteKeyword>
  autoCompleteTags: Array<IAutoCompleteTag>
}

export class GlobalStats implements IGlobalStats {
  totalSales: number;
  resourcesAuthor: number;
  totalResources: number;
  positiveReviews: number;
  categories: IStat[];
  autoCompleteKeywords: IAutoCompleteKeyword[];
  autoCompleteTags: IAutoCompleteTag[];
}

