import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

export default function SmoothScroll({
  children
}: {
  children: React.ReactNode;
}) {
  const { scrollYProgress } = useScroll();

  scrollYProgress.set(0)

  const smoothProgress = useSpring(scrollYProgress, { mass: 0.1 })

  const [contentHeight, setContentHeight] = useState(0);


  let y = useTransform(smoothProgress, v => v * -(contentHeight - window.innerHeight))



  const contentRef = useRef<HTMLDivElement>(null);



  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight)
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    window.addEventListener("emergency-resize", handleResize);
    window.addEventListener("wheel", handleResize);
  }, [contentRef]);

  return (
    <>

      <div style={{ height: contentHeight }} />


      <motion.div
        className="scrollBody"
        style={{ y }}
        ref={contentRef}
      >
        {children}
      </motion.div>
    </>
  )
}