import { useParams } from "react-router-dom";

import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useCallback, useEffect, useState } from "react";
import Stars from "../../Customization/Stars";

//@ts-ignore
import defaultImage from "../../../assets/products/defaultProduct.webp"


export default function MarketProduct() {
  const [isZoomed, setIsZoomed] = useState(false);
  const [customAmount, setCustomAmount] = useState(false);

  const { id } = useParams();

  const handleZoomChange = useCallback((shouldZoom: boolean) => {
    setIsZoomed(shouldZoom);
  }, []);

  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "select_amount_custom") {
      setCustomAmount(true);
    } else {
      setCustomAmount(false);
    }
  };

  const [name, setName] = useState("Cloudy Coding");
  const [image, setImage] = useState(defaultImage)
  const [stars, setStars] = useState(0)
  const [offer, setOffer] = useState(0)
  const [price, setPrice] = useState(0)
  const [description, setDescription] = useState("Default description")
  const [productType, setProductType] = useState("file")
  const [external, setExternal] = useState("https://cloudycoding.com")
  const [userName, setUserName] = useState("Cloudy Coding")

  useEffect(() => {
    (async () => {
      const productInfo = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/products/get?uuid=" + id)
      const productJSON = await productInfo.json()

      const userInfo = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/profile/getInfo?username=" + productJSON.body.author)
      const userJSON = await userInfo.json()

      const imageData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/images/get/" + productJSON.body.image)
      const imageJSON = await imageData.json()
      setName(productJSON.body.name)
      setImage(imageJSON.body.base64)
      setStars(productJSON.body.stars)
      setOffer(productJSON.body.offer)
      setPrice(productJSON.body.price)
      setDescription(productJSON.body.description)
      setProductType(productJSON.body.type)
      setExternal(productJSON.body.externalURL)
      setUserName(userJSON.body.name)

      window.dispatchEvent(new CustomEvent("emergency-resize"))

    })()
  }, [])

  return (
    <div className="bg-white border-gray-200 border py-12 w-full mx-auto">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
        <form
          action="#"
          method="POST"
          className="md:flex-1 flex max-xl:flex-col xl:flex-row gap-8"
        >
          <div className="flex-1 gap-8 flex max-md:flex-col max-md:justify-center max-md:items-center md:flex-row">
            <div className="flex items-center justify-center px-4">
              <div className="flex-1 w-fit rounded-lg">
                <ControlledZoom
                  isZoomed={isZoomed}
                  onZoomChange={handleZoomChange}
                >
                  <img
                    className="object-contain max-xl:w-[280px] xl:w-[350px]"
                    src={image}
                    width="350px"
                    alt="Product Alt"
                  />
                </ControlledZoom>
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-3">
              <h2 className="text-2xl font-bold text-gray-800">
                {name}
              </h2>
              <a href="#reviews" className="flex gap-2 items-center">
                <Stars value={stars} width="4"></Stars>
                <span className="text-gray-400">({stars})</span>
                <span className="text-gray-400 font-semibold">0 reviews</span>
              </a>


              <div className="flex gap-2 items-end">
                {offer && offer > 0 ? <>
                  <span className="text-2xl">${(price * (1 - (offer / 100))).toFixed(2)}</span>
                  <span className="text-sm line-through">$ {price.toFixed(2)}</span>
                  <span className="text-green-500 font-semibold">${offer * 100}% OFF!</span></> :
                  <span className="text-2xl">${price.toFixed(2)}</span>
                }

              </div>

              {/* <div className="flex flex-col gap-1">
                <label
                  htmlFor="amount"
                  className="block font-medium text-gray-900"
                >
                  Select an amount:{" "}
                  <span className="font-semibold text-gray-400">
                    (15 disponible units)
                  </span>
                </label>
                <div className="flex flex-col lg:flex-row gap-1">
                  <select
                    id="amount"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-indigo-400 block w-full lg:w-fit p-2.5 lg:py-2.5 lg:px-5 font-semibold"
                    onChange={handleSelectChange}
                  >
                    <option defaultValue="select_amount_1">1 Unit</option>
                    <option value="select_amount_2">2 Units</option>
                    <option value="select_amount_3">3 Units</option>
                    <option value="select_amount_4">4 Units</option>
                    <option value="select_amount_custom">Custom amount</option>
                  </select>
                  {customAmount && (
                    <input
                      type="number"
                      name="input_amount_custom"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-indigo-400 w-fit p-2.5"
                      placeholder="Enter custom amount..."
                    />
                  )}
                </div>
              </div> */}

              <div className="flex flex-col gap-1">
                <span className="font-medium text-gray-700">
                  Product Description:
                </span>
                <p className="text-gray-600 text-sm">
                  {description}
                </p>
              </div>
            </div>
          </div>

          <div className="max-xl:flex-1 flex flex-col max-w-1/4 justify-between items-between gap-5">
            <div className="border border-gray-300 gap-2 p-5 rounded-lg">
              <h3 className="mb-1 text-lg font-semibold text-gray-700">
                Author information:
              </h3>
              <ul className="max-w-md space-y-1 list-disc list-inside text-gray-600 ms-3">
                <li>
                  <span className="font-semibold">Name</span>: {userName}
                </li>
                {/* <li>
                  <span className="font-semibold">Location</span>: His House
                </li> */}
                {/* <li>
                  <span className="font-semibold">1</span> Total products
                </li> */}
                {/* <li>
                  <span className="font-semibold">1</span> Sales
                </li> */}
                {/* <li>
                  <span className="font-semibold">1</span> Positive reviews
                </li> */}
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              {productType === "third_party" ? <a
                href={process.env.REACT_APP_API_WEB_BASE_URL + "/stats/redirect?url=" + encodeURI(external)}
                target="_blank"
                rel="noreferrer"
                className="w-full bg-indigo-500 text-white py-2 px-4 rounded-lg font-bold hover:bg-indigo-500/75 duration-100"
              >
                Go to the third party website
              </a> : <button
                type="submit"
                name="action"
                value="cart"
                className="w-full bg-indigo-500 text-white py-2 px-4 rounded-lg font-bold hover:bg-indigo-500/75 duration-100"
              >
                Add to Cart
              </button>}

              {/* <button
                type="button"
                className="w-full bg-indigo-400 text-white py-2 px-4 rounded-lg font-bold hover:bg-indigo-400/75 duration-100"
              >
                Add to Wishlist
              </button> */}
              <div className="flex flex-col pt-2">
                {/* <span className="text-center font-semibold">
                  View more{" "}
                  <a href="/marketplace/author/authorname" className="text-indigo-400">
                    author products
                  </a>
                </span> */}
                {/* <span className="text-center font-semibold">
                  View{" "}
                  <a href="#reviews" className="text-indigo-400">
                    product reviews
                  </a>
                </span> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
