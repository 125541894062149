
export default function Footer() {
  return (
    <footer className="bg-gray-100 py-8">
      <div className="container mx-auto">
        <div className="w-full max-md:px-5">
          <div className="text-center min-w-[100px] pb-6">
            <h3 className="font-bold text-xl text-indigo-600">Cloudy Coding</h3>
          </div>
          <div className="px-4 sm:px-0 text-gray-800 flex justify-evenly flex-wrap mx-auto max-sm:justify-center max-md:gap-10">
            <div className="min-w-[100px] max-sm:text-center">
              <div className="text-sm uppercase text-indigo-600 font-bold text-left">
                Resources
              </div>
              <a className="my-3 block" href="/profile/products">
                Create a new resource
              </a>
              <a className="my-3 block" href="/marketplace?tags=trending">
                Trending resources
              </a>
              <a className="my-3 block" href="/profile/products">
                Manage your resources
              </a>
            </div>
            <div className="min-w-[100px] max-sm:text-center">
              <div className="text-sm uppercase text-indigo-600 font-bold text-left">
                Support
              </div>
              <a className="my-3 block" href="/support">
                Support Section
              </a>
              <a className="my-3 block" href="mailto:support_market@cloudycoding.com">
                Mail
              </a>
              <a className="my-3 block" href="https://discord.gg/CYk6XSZWZd">
                Discord
              </a>
            </div>
            <div className="min-w-[100px] max-sm:text-center">
              <div className="text-sm uppercase text-indigo-600 font-bold text-left">
                Contact us
              </div>
              <a className="my-3 block" href="mailto:contact_market@cloudycoding.com">
                Mail
                <span className="text-teal-600 text-xs p-1"></span>
              </a>
              <a className="my-3 block" href="tel:+54 9 3874732727">
                Telephone
                <span className="text-teal-600 text-xs p-1"></span>
              </a>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 py-2">
          <div className="flex pt-8 m-auto border-t text-gray-800 text-sm flex-col items-center gap-4">
            <div className="flex-row flex gap-2">
              <a
                href={process.env.REACT_APP_WEB_BASE_URL}
                className="w-6 h-6 bg-indigo-100 rounded-full text-center"
              >
                -
              </a>
              <a
                href={process.env.REACT_APP_WEB_BASE_URL}
                className="w-6 h-6 bg-indigo-100 rounded-full text-center"
              >
                -
              </a>
              <a
                href={process.env.REACT_APP_WEB_BASE_URL}
                className="w-6 h-6 bg-indigo-100 rounded-full text-center"
              >
                -
              </a>
              <a
                href={process.env.REACT_APP_WEB_BASE_URL}
                className="w-6 h-6 bg-indigo-100 rounded-full text-center"
              >
                -
              </a>
              <a
                href={process.env.REACT_APP_WEB_BASE_URL}
                className="w-6 h-6 bg-indigo-100 rounded-full text-center"
              >
                -
              </a>
            </div>
            <div>© Copyright {new Date().getFullYear()}. All Rights Reserved.</div>
          </div>
        </div>
      </div>
    </footer>
  );
}
