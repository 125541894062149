import { MouseEvent } from "react";

interface CategoryCheckBoxProps {
  value: string;
  label: string;
}

const CategoryCheckBox: React.FC<CategoryCheckBoxProps> = ({ value, label }) => {

  const checkOtherBoxes = (e: MouseEvent) => {
    //@ts-ignore
    const boxes = [...document.querySelectorAll('input[type="checkbox"][name="category"]')].filter(v => v.value !== e.target.value)
    boxes.forEach(box => {
      box.checked = false
    })
  }

  return (
    <li className="w-full rounded-t-lg">
      <div className="flex items-center ps-3">
        <input
          type="checkbox"
          value={value}
          name="category"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
          onClick={checkOtherBoxes}
        />
        <label
          htmlFor="minecraft-java-filter"
          className="w-full py-1 ms-2 text-sm font-medium text-gray-900 capitalize"
        >
          {label}
        </label>
      </div>
    </li>
  )
}

export default CategoryCheckBox