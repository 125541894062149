import secureLocalStorage from "react-secure-storage"
import { IUserToken } from "../../../Global/classes/UserToken.js"

export async function postRequest(url: string, body: Object) {
  const authorization = secureLocalStorage.getItem("authorization") ? secureLocalStorage.getItem("authorization") as IUserToken : null
  const fetchAction = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: authorization ? {
      "Content-Type": "application/json",
      "Authorization": authorization.token as string
    } : {
      "Content-Type": "application/json",
    }
  })

  const json = await fetchAction.json()
  if (json.status === 401) secureLocalStorage.removeItem("authorization")
  return json
}

