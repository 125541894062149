interface ProductProps {
  productId: number;
  productImg: string;
  productType: string;
  productName: string;
  productPrice: number;
  productOriginalPrice: number;
  productStock: number;
  handleEdit: (arg0: number) => void;
  handleDelete: (arg0: number) => void;
  unlimited: boolean;
}

export function Product({
  productId,
  productImg,
  productType,
  productName,
  productPrice,
  productOriginalPrice,
  productStock,
  handleEdit,
  handleDelete,
  unlimited
}: ProductProps) {
  return (
    <div className="flex max-md:flex-col max-md:flex-1 max-md:min-w-[220px] max-md:gap-3 max-md:text-center max-md:py-8 flex-wrap items-center bg-gray-100 rounded-lg px-6 py-3">
      <div className="px-4 flex-1">
        <div className="w-full flex flex-row items-center">
          <img
            src={productImg}
            alt=""
            className="max-h-36 w-full object-cover rounded-lg shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]"
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col text-left px-4">
        <a className="text-xl font-semibold text-gray-700" href={"/marketplace/product/" + productId}>{productName}</a>
        <span className="text-gray-500 capitalize">{productType.replaceAll("_", " ")} Product</span>
      </div>
      <div className="px-4 flex-1">
        <p className="text-lg font-bold text-indigo-400">${productPrice}</p>
        {productPrice !== productOriginalPrice && (
          <span className="text-xs text-gray-500 line-through">$100.00</span>
        )}
      </div>
      <div className="px-4 flex-1 text-left">
        {
          unlimited ? <p className="text-gray-700">Unlimited units available</p> : <p className="text-gray-700">{productStock} units available</p>
        }

      </div>
      <div className="px-4 text-center flex-1 flex flex-col gap-2">
        <button
          className="max-sm:w-full sm:flex-1 flex flex-row gap-2 items-center justify-center text-white bg-yellow-400 hover:bg-yellow-400/80 duration-100 py-2 rounded-lg"
          onClick={() => handleEdit(productId)}
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
            </g>
          </svg>
          <span className="font-bold">Edit</span>
        </button>
        <button
          className="max-sm:w-full sm:flex-1 flex flex-row gap-2 items-center justify-center text-gray-100 bg-red-400 hover:bg-red-400/80 duration-100 py-2 rounded-lg"
          onClick={() => handleDelete(productId)}
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M17 12C17 11.4477 16.5523 11 16 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44771 13 8 13H16C16.5523 13 17 12.5523 17 12Z"
                fill="currentColor"
              ></path>{" "}
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 20.9932C7.03321 20.9932 3.00683 16.9668 3.00683 12C3.00683 7.03321 7.03321 3.00683 12 3.00683C16.9668 3.00683 20.9932 7.03321 20.9932 12C20.9932 16.9668 16.9668 20.9932 12 20.9932Z"
                fill="currentColor"
              ></path>{" "}
            </g>
          </svg>
          <span className="font-bold">Remove</span>
        </button>
      </div>
    </div>
  );
}
