import { IProduct } from "./Product.js"

export interface IProductToCart {
  amount: number
  product: IProduct
}

export interface ICart {
  userUUID: String;
  uuid: string;
  products: Array<IProductToCart>;
}

export class Cart implements ICart {
  userUUID: String = "";
  uuid: string = "";
  products: IProductToCart[] = new Array<IProductToCart>();

}