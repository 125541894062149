import Slider from "./Slider/Slider";
import Select, { StylesConfig } from "react-select";
import { Player } from "@lordicon/react";
import SearchIcon from "../../assets/icons/search.json";
import { useEffect, useRef, useState } from "react";
import { IGlobalStats } from "../../../../Global/classes/GlobalStats";
import secureLocalStorage from "react-secure-storage"

interface IOptions {
  value: string, label: string, defaultValue: boolean
}

export default function Header() {




  const playerRef = useRef<Player>(null);
  const [category, setCategory] = useState("all")
  const [query, setQuery] = useState("")
  let defaultOptions = secureLocalStorage.getItem("stats") != null ? parseCategories(secureLocalStorage.getItem("stats") as IGlobalStats) : new Array<IOptions>();





  const [options, setOptions] = useState(defaultOptions)
  const [defaultOption] = useState(defaultOptions[0])

  const selectStyle: StylesConfig = {
    valueContainer: (styles) => {
      styles.padding = "5px 10px";
      styles.fontFamily =
        'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
      styles.fontSize = "14px";
      styles.color = "#6366f1";
      styles.fontWeight = "600";
      styles.borderRadius = "30px !important";
      styles.caretColor = "transparent";

      return styles;
    },
    control: (styles) => {
      styles.fontFamily =
        'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
      styles.fontSize = "14px";
      styles.color = "#6366f1";
      styles.fontWeight = "600";
      styles.borderRadius = "5px";
      styles.borderColor = "#6366f1";
      styles.borderWidth = "1px";
      styles.minWidth = "25vh";

      return styles;
    },
  };

  //Functions


  function parseCategories(stats: IGlobalStats) {
    const categories = new Array<IOptions>();
    // eslint-disable-next-line array-callback-return
    stats?.categories?.map((v, k) => {
      const element = { value: v.value, label: v.key, defaultValue: k === 0 ? true : false }
      categories.push(element)
    })
    return categories
  }

  function restartPlayerAnimation() {
    setTimeout(() => {
      playerRef.current?.playFromBeginning();
    }, 1000);
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    window.location.assign(`/marketplace?category=${category}&name=${query}`)
  }


  //Functions

  useEffect(() => {
    //@ts-ignore

    if (secureLocalStorage.getItem("stats") != null) setOptions(parseCategories(secureLocalStorage.getItem("stats")))
    //@ts-ignore
    playerRef.current?.playFromBeginning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <header className="max-lg:pt-32">
      <div>
        <div className="absolute w-full h-3/4 bg-indigo-100 z-[-50] scale-y-125 skew-y-[9deg]"></div>
        <div className="container mx-auto px-8 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 z-50">
          <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
            <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-700 sm:text-4xl md:mx-auto">
                  Buy or sell the best software in the market.{" "}
                  <span className="text-indigo-500">
                    Fast and easy
                  </span>
                </h2>
                <p className="text-base text-gray-600 md:text-lg">
                  Experience swift and secure purchasing with our platform. We accept a wide array of payment methods, and offer currency exchange services. Additionally, you can promote your business with us. Start now!
                </p>
              </div>

              <form action="#" method="POST" onSubmit={handleSubmit}>
                <div className="flex max-sm:flex-col max-sm:flex-wrap gap-2">
                  <Select
                    options={options}
                    id="small"
                    className="max-sm:flex-1 max-sm:w-full"
                    defaultValue={defaultOption}
                    styles={selectStyle}
                    //@ts-ignore
                    onChange={(v) => setCategory(v.value)}
                  ></Select>

                  <div className="flex-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Player
                        ref={playerRef}
                        size={16}
                        icon={SearchIcon}
                        onComplete={() => restartPlayerAnimation}
                      ></Player>
                    </div>

                    <input
                      type="search"
                      name="search"
                      //@ts-ignore
                      onChange={(v) => { setQuery(v.target.value) }}
                      placeholder="Search for a specific resource..."
                      className="w-full max-sm:py-2.5 flex gap-3 p-2.5 h-full text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg pl-10 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                    />
                  </div>
                  <button
                    type="submit"

                    className="max-sm:flex-1 text-center max-sm:py-2.5 gap-3 p-2.5 h-full text-sm font-medium text-white bg-indigo-500 rounded-lg border border-indigo-600 hover:bg-indigo-800 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <section className="flex flex-col gap-5">
          <h2 className="text-center font-semibold text-2xl">
            Trending resources
          </h2>
          <Slider></Slider>
        </section>
      </div>
    </header>
  );
}
