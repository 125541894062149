import { useState, useRef, useEffect } from "react";
import { Player } from "@lordicon/react";
import SearchIcon from "../../../../../assets/icons/upload-file.json";
import { RequiredTick } from "../../../../Customization/RequiredTick";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { WithContext as ReactTags } from "react-tag-input";
import { createProductRequest } from "../../../../../utils/createProductRequest";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];





export default function FileProduct({ closeModal, setProducts }: any) {

  useEffect(() => {
    (async () => {
      const data = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/stats/get/all")
      const json = await data.json()
      setAutocompleteKeywords(json.body.autoCompleteKeywords)
      setAutocompleteTags(json.body.autoCompleteTags)
      setKeywordsSuggestions(autocompleteKeywords.map((keyword) => {
        return {
          id: keyword.id,
          text: keyword.text,
        };
      }))
      setTagsSuggestions(autocompleteTags.map((tag) => {
        return {
          id: tag.id,
          text: tag.text,
        };
      }))
      setCategories(json.body.categories)
    })()
  }, [])

  const [autocompleteKeywords, setAutocompleteKeywords] = useState([{
    id: "Software",
    text: "Software"
  }])

  const [autocompleteTags, setAutocompleteTags] = useState([
    {
      id: "Software",
      text: "Software",
    },
  ]);

  const [categories, setCategories] = useState([{
    value: "all", key: "General Category"
  }])

  const [keywordsSuggestions, setKeywordsSuggestions] = useState(autocompleteKeywords.map((keyword) => {
    return {
      id: keyword.id,
      text: keyword.text,
    };
  }))

  const [tagsSuggestions, setTagsSuggestions] = useState(autocompleteTags.map((tag) => {
    return {
      id: tag.id,
      text: tag.text,
    };
  }));

  const [keywords, setKeywords] = useState([]);

  const [tags, setTags] = useState([]);

  const [remainingKeywords, setRemainingKeywords] = useState<any>(null);
  const [remainingTags, setRemainingTags] = useState<any>(null);

  function handleFilterQuery(
    textInputValue: string,
    possibleSuggestionsArray: any[],
    change: any
  ) {
    const lowerCaseQuery = textInputValue.toLowerCase();

    const filteredSuggestions = possibleSuggestionsArray.filter(
      (suggest: any) => {
        return suggest.text.toLowerCase().includes(lowerCaseQuery);
      }
    );

    var remainingCount = filteredSuggestions.length - 8;
    if (remainingCount < 0) remainingCount = 0;

    if (change === "keyword") {
      setRemainingKeywords(remainingCount);
    } else if (change === "tag") {
      setRemainingTags(remainingCount);
    }

    if (!lowerCaseQuery || lowerCaseQuery === "") {
      setRemainingKeywords(null);
      setRemainingTags(null);
    }

    return filteredSuggestions.slice(0, 8);
  }

  function handleBlur() {
    setRemainingKeywords(null);
  }

  const [filename, setFilename] = useState<string>("");
  const [imagePreviewSrc, setImagePreviewSrc] = useState<string>("");
  const [isEventListenerAdded, setIsEventListenerAdded] =
    useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [stock, setStock] = useState<any>(1);
  const [unlimitedStock, setUnlimitedStock] = useState<boolean>(false);


  const playerRef = useRef<Player>(null);

  useEffect(() => {
    playerRef.current?.playFromBeginning();
  }, []);

  const handleTagDelete = (i: any) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleTagAddition = (tag: any) => {
    //@ts-ignore
    setTags([...tags, tag]);
  };

  const handleTagDrag = (tag: any, currPos: any, newPos: any) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    //@ts-ignore

    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleKeywordDelete = (i: any) => {
    setKeywords(keywords.filter((keyword, index) => index !== i));
  };

  const handleKeywordAddition = (keyword: any) => {
    //@ts-ignore
    setKeywords([...keywords, keyword]);
  };

  const handleKeywordDrag = (keyword: any, currPos: any, newPos: any) => {
    const newKeywords = keywords.slice();

    newKeywords.splice(currPos, 1);
    //@ts-ignore
    newKeywords.splice(newPos, 0, keyword);

    // re-render
    setKeywords(newKeywords);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFilename(file.name);

      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreviewSrc(e.target?.result as string);
      };
      reader.readAsDataURL(file);

      if (!isEventListenerAdded) {
        setIsEventListenerAdded(true);
      }
    } else {
      setFilename("");
      setImagePreviewSrc("");

      if (isEventListenerAdded) {
        setIsEventListenerAdded(false);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  function humanFileSize(bytes: any, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  function getFileExt(file: string) {
    const dotPosition = file.indexOf(".");
    const extension = file.substring(dotPosition + 1);

    return extension;
  }

  function getFileName(file: string) {
    const dotPosition = file.indexOf(".");
    const filename = file.substring(0, dotPosition);
    return filename;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault()
    if (!filename || filename === "") {
      toast.error("Please select a product image.");
      return;
    }

    if (!file) {
      toast.error("Please select a product file.");
      return;
    }

    createProductRequest(event, closeModal, "fileproduct", setProducts)




  };

  return (
    <form
      method="post"
      action={process.env.REACT_APP_API_WEB_BASE_URL + "/products/create/fileproduct"}
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <div className="grid gap-4 grid-cols-2 max-lg:grid-cols-1">
        <div className="col-span-1 grid gap-4 mb-4 grid-cols-2">
          <div className="col-span-2">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Name
              <RequiredTick />
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5"
              placeholder="Type product name"
              required={true}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <label
              htmlFor="price"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Price
              <RequiredTick />
            </label>
            <input
              type="text"
              name="price"
              id="price"
              pattern="[0-9]+([\.,][0-9]+)?"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5"
              placeholder="$19.99"

              required={true}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Category
              <RequiredTick />
            </label>
            <select
              id="category"
              required={true}
              name="category"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5"
            >
              {categories.map(category => (
                < option value={category.value} > {category.key}</option>
              ))}

            </select>
          </div>
          <div className="col-span-2">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Stock
              <RequiredTick />
            </label>
            <input
              id="stock"
              required={!unlimitedStock}
              disabled={unlimitedStock}
              name="stock"
              type="number"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              className={`bg-gray-50 text-gray-900 border border-gray-300 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5 disabled:bg-gray-200/90 disabled:text-gray-500 disabled:cursor-not-allowed`}
            ></input>
            <div className="flex items-center mt-2">
              <label
                htmlFor="unlimited-stock"
                className="block text-sm font-medium text-gray-900"
              >
                Unlimited stock?
              </label>
              <input
                type="checkbox"
                id="unlimited-stock"
                name="unlimited-stock"
                className="ml-2 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                checked={unlimitedStock}
                onChange={(e) => setUnlimitedStock(e.target.checked)}
              />
            </div>
          </div>

          <div className="col-span-2">
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Product Description
              <RequiredTick />
            </label>
            <textarea
              id="description"
              name="description"
              rows={4}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              placeholder="Write product description here"
            ></textarea>
          </div>
          <div className="col-span-2 flex flex-col gap-1">
            <span className="block mb-1 text-sm font-medium text-gray-900">
              Product photo
              <RequiredTick />
            </span>
            <div
              id="image-preview"
              className={`w-full px-3 py-6 bg-gray-50 hover:bg-gray-100 duration-100 rounded-lg items-center mx-auto text-center cursor-pointer ${!imagePreviewSrc && "border-dashed border-2 border-gray-400"
                }`}
              onClick={() => document.getElementById("upload")?.click()}
            >
              {imagePreviewSrc && (
                <img
                  src={imagePreviewSrc}
                  className="max-h-48 rounded-lg mx-auto mb-2"
                  alt="Product Preview"
                />
              )}
              <input
                id="upload"
                type="file"
                name="productImage"
                className="hidden"
                accept="image/png, image/jpeg"
                onChange={handleImageChange}
              />
              <label htmlFor="upload" className="cursor-pointer">
                <div className={`${imagePreviewSrc && "hidden"}`}>
                  <div className="flex flex-row gap-3 justify-center items-center">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      height="40px"
                      width="40px"
                      className="text-indigo-400"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M14.2647 15.9377L12.5473 14.2346C11.758 13.4519 11.3633 13.0605 10.9089 12.9137C10.5092 12.7845 10.079 12.7845 9.67922 12.9137C9.22485 13.0605 8.83017 13.4519 8.04082 14.2346L4.04193 18.2622M14.2647 15.9377L14.606 15.5991C15.412 14.7999 15.8149 14.4003 16.2773 14.2545C16.6839 14.1262 17.1208 14.1312 17.5244 14.2688C17.9832 14.4253 18.3769 14.834 19.1642 15.6515L20 16.5001M14.2647 15.9377L18.22 19.9628M18.22 19.9628C17.8703 20 17.4213 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.7157 19.5903 4.40973 19.2843 4.21799 18.908C4.12583 18.7271 4.07264 18.5226 4.04193 18.2622M18.22 19.9628C18.5007 19.9329 18.7175 19.8791 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V13M11 4H7.2C6.07989 4 5.51984 4 5.09202 4.21799C4.7157 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.0799 4 7.2V16.8C4 17.4466 4 17.9066 4.04193 18.2622M18 9V6M18 6V3M18 6H21M18 6H15"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>{" "}
                      </g>
                    </svg>

                    <h5 className="text-xl font-bold tracking-tight text-gray-700">
                      Upload product photo
                    </h5>
                  </div>
                  <span className="block font-normal text-sm text-gray-400 md:px-6">
                    Choose image size should be less than{" "}
                    <b className="text-gray-600">6mb</b>
                  </span>
                  <span className="block font-normal text-sm text-gray-400 md:px-6">
                    File should be in <b className="text-gray-600">.png</b> or{" "}
                    <b className="text-gray-600">.jpg</b> format.
                  </span>
                </div>

                <span id="filename" className="text-gray-500 bg-gray-200 z-50">
                  {filename}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className="col-span-1 flex flex-col gap-4 mb-4">
          <div className="col-span-2">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Keywords
            </label>
            <ReactTags
              tags={keywords}
              delimiters={delimiters}
              suggestions={keywordsSuggestions}
              handleDelete={handleKeywordDelete}
              handleAddition={handleKeywordAddition}
              handleDrag={handleKeywordDrag}
              handleInputBlur={handleBlur}
              handleFilterSuggestions={(textInputValue, possibleSuggestions) =>
                handleFilterQuery(
                  textInputValue,
                  possibleSuggestions,
                  "keyword"
                )
              }
              // name="keywords"
              classNames={{
                tags: "flex flex-col",
                tagInputField:
                  "my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5",
                selected: "flex gap-1",
                tag: "flex gap-1 bg-indigo-600/80 py-1 px-2 rounded-lg font-semibold text-white",
                remove: "hover:text-gray-200",
                suggestions:
                  "bg-gray-100 rounded-lg p-3 select:bg-gray-200 gap-2 *:flex *:flex-wrap *:gap-3 *:*:px-2 *:*:bg-indigo-400 *:*:font-semibold *:*:text-white *:*:py-1 *:*:rounded-lg",
                activeSuggestion: "bg-indigo-200 rounded-sm cursor-pointer",
              }}
              renderSuggestion={({ text }, query) => (
                <span>
                  {text}{" "}
                  {query && <span className="text-yellow-400">({query})</span>}
                </span>
              )}
              inputFieldPosition="bottom"
              autocomplete
              placeholder="Select keywords for your product..."
              minQueryLength={1}
            />
            {remainingKeywords > 0 && (
              <span className="block mt-2 text-sm font-medium text-gray-900">
                ... and more avilable keywords
              </span>
            )}
          </div>

          <div className="col-span-2">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Product tags
            </label>
            <ReactTags
              tags={tags}
              delimiters={delimiters}
              suggestions={tagsSuggestions}
              handleDelete={handleTagDelete}
              handleAddition={handleTagAddition}
              handleDrag={handleTagDrag}
              handleFilterSuggestions={(textInputValue, possibleSuggestions) =>
                handleFilterQuery(textInputValue, possibleSuggestions, "tag")
              }
              // name="tags"
              classNames={{
                tags: "flex flex-col",
                tagInputField:
                  "my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5",
                selected: "flex gap-1",
                tag: "flex gap-1 bg-yellow-600/80 py-1 px-2 rounded-lg font-semibold text-white",
                remove: "hover:text-gray-200",
                suggestions:
                  "bg-gray-100 rounded-lg p-3 select:bg-gray-200 gap-2 *:flex *:flex-wrap *:gap-3 *:*:px-2 *:*:bg-yellow-500 *:*:font-semibold *:*:text-white *:*:py-1 *:*:rounded-lg",
                activeSuggestion: "cursor-pointer",
              }}
              renderSuggestion={({ text }, query) => (
                <span>
                  {text}{" "}
                  {query && <span className="text-white">({query})</span>}
                </span>
              )}
              inputFieldPosition="bottom"
              autocomplete
              placeholder="Select tags for your product..."
              minQueryLength={1}
            />
            {remainingTags > 0 && (
              <span className="block mt-2 text-sm font-medium text-gray-900">
                ... and more avilable tags
              </span>
            )}
          </div>
          <div className="col-span-2 flex flex-col gap-1">
            <label
              htmlFor="uploadfile"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Product file
              <RequiredTick />
            </label>
            <div
              id="file-preview"
              className={`w-full bg-gray-50 hover:bg-gray-100 duration-100 py-6 rounded-lg items-center mx-auto text-center cursor-pointer ${!file && "border-dashed border-2 border-gray-400"
                }`}
              onClick={() => document.getElementById("uploadfile")?.click()}
            >
              <input
                id="uploadfile"
                type="file"
                name="productFile"
                className="hidden"
                onChange={handleFileChange}
              />
              <label className="cursor-pointer">
                <div className={`${file && "hidden"} flex flex-col gap-2`}>
                  <div className="w-full flex items-center justify-center">
                    <Player
                      ref={playerRef}
                      size={40}
                      icon={SearchIcon}
                      colorize="#6366f1"
                      onComplete={() =>
                        setTimeout(() => {
                          playerRef.current?.playFromBeginning();
                        }, 500)
                      }
                    ></Player>
                  </div>

                  <div>
                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-700">
                      Upload product file
                    </h5>
                    <span className="block font-normal text-sm text-gray-400 md:px-6">
                      Choose file size should be less than{" "}
                      <b className="text-gray-600">2gb</b>
                    </span>
                    <span className="block font-normal text-sm text-gray-400 md:px-6">
                      File can be uploaded in{" "}
                      <b className="text-gray-600">any format</b>. If you want
                      to set multiple files, use a{" "}
                      <b className="text-gray-600">compressed</b> file like{" "}
                      <b className="text-gray-600">.rar</b> or{" "}
                      <b className="text-gray-600">.zip</b>
                    </span>
                  </div>
                </div>
                {file && (
                  <div
                    id="filename"
                    className="text-gray-700 bg-gray-100 border border-2 border-gray-200 rounded-lg p-5 z-50"
                  >
                    <div className="font-bold bg-gray-200 rounded-lg p-3">
                      <span>{file.name}</span>
                    </div>
                  </div>
                )}
              </label>
            </div>
            {file && (
              <div className="text-gray-700 p-5 w-full bg-gray-50 border border-2 border-gray-200 rounded-lg">
                <span className="font-semibold text-lg">File details:</span>
                <ul className="list-disc list-inside">
                  <li>
                    <span className="font-semibold">Name</span>:{" "}
                    {getFileName(file.name)}
                  </li>
                  <li>
                    <span className="font-semibold">Type</span>:{" "}
                    {getFileExt(file.name)}
                  </li>
                  <li>
                    <span className="font-semibold">Size</span>:{" "}
                    {humanFileSize(file.size)}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row gap-3 max-sm:flex-col justify-end items-center">
        <button
          type="button"
          onClick={closeModal}
          className="text-white inline-flex items-center bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          <svg
            className="me-1 -ms-1 w-5 h-5 rotate-45"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          Cancel
        </button>

        <button
          type="submit"
          className="text-white inline-flex items-center bg-indigo-400 hover:bg-indigo-500 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          <svg
            className="me-1 -ms-1 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          Create product
        </button>
      </div>
      <input type="text" value={keywords.map(v => {
        //@ts-ignore
        return v.id
      }).join(",")} name="keywords" style={{ display: "none" }} />
      <input type="text" value={tags.map(v => {
        //@ts-ignore
        return v.id
      }).join(",")} name="tags" style={{ display: "none" }} />
    </form>
  );
}
