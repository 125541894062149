import MarketNavbar from "../../Navigation/Market/MarketNavbar";
import Footer from "../../Navigation/Footer";
import ScrollMotion from "../../Scroll/ScrollMotion";
import ProfileSidebar from "../../Navigation/Profile/ProfileSidebar";
import { Cart } from "../../Profile/ShoppingCart/Cart";

import { getQueryParams } from "../../../utils/getQueryParams";
import { useLocation } from "react-router-dom";

import RenderNotification from "../../Notifications/RenderNotification";
import ErrorBoundary from "../Errors/ErrorBoundary";

export default function ShoppingCart() {
  const location = useLocation();
  const { error, success } = getQueryParams(location);
  return (
    <ErrorBoundary>
      <RenderNotification error={error} success={success} />
      <ScrollMotion>
        <MarketNavbar></MarketNavbar>
        <main className="bg-gray-100/50 pt-44 lg:pt-16 pb-20 max-sm:px-4 flex flex-col gap-6 xl:gap-12">
          <div className="flex flex-wrap max-xl:flex-col xl:flex-row gap-6 xl:gap-12 container mx-auto">
            <ProfileSidebar></ProfileSidebar>
            <Cart></Cart>
          </div>
        </main>
        <Footer></Footer>
      </ScrollMotion>
    </ErrorBoundary>
  );
}
