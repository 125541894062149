import { useEffect, useState } from "react";

interface ProductCardProps {
  name: string,
  uuid: string,
  image: string,
  author: string,
  price: number,
  offer: number,
  unlimited: boolean,
  stock: number,
  amount: number
}

const ProductCard: React.FC<ProductCardProps> = ({ name, uuid, image, author, price, offer, unlimited, stock, amount }) => {
  const [customAmount, setCustomAmount] = useState(false);
  const [currentAmount, setCurrentAmount] = useState(1);
  const [nowPrice, setNowPrice] = useState(price * (1 - (offer / 100)))

  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value;


    if (selectedValue === "select_amount_custom" || amount > 1) {
      setCurrentAmount(1)
      setCustomAmount(true);
    } else {
      setCurrentAmount(Number(event.currentTarget.value))
      setCustomAmount(false);
    }
  };


  useEffect(() => {
    //@ts-ignore
    setNowPrice(((price * (1 - (offer / 100))) * currentAmount).toFixed(2))
  }, [price, offer, currentAmount, nowPrice])


  return (
    <div className="flex max-md:flex-col max-md:flex-1 max-md:min-w-[220px] max-md:gap-3 max-md:text-center max-md:bg-gray-50 max-md:py-8 flex-wrap items-center md:odd:bg-gray-50 rounded-lg px-6 py-3">
      <div className="px-4 flex-1">
        <a className="w-full flex flex-row items-center" href={"/marketplace/product/" + uuid}>
          <img
            src={image}
            alt=""
            className="max-h-36 w-full object-cover rounded-lg shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]"
          />
        </a>
      </div>
      <div className="flex-1 flex flex-col px-4">
        <h2 className="text-xl font-semibold text-gray-700">{name}</h2>
        <p className="text-gray-500">
          By{" "}
          <a href={"/profiles/" + uuid} className="text-indigo-400">
            {author}
          </a>
        </p>
      </div>
      <div className="px-4 block flex-1">
        {offer && offer > 0 ?
          <>
            <p className="text-lg font-bold text-indigo-400">${(price * (1 - (offer / 100))).toFixed(2)}</p>
            <span className="text-xs text-gray-500 line-through">${price.toFixed(2)}</span>
          </> :
          <p className="text-lg font-bold text-indigo-400">${price.toFixed(2)}</p>
        }

      </div>
      <div className="px-4 flex-1">
        <div className="flex flex-col gap-1">
          {!unlimited && stock > 0 ?
            <label htmlFor="amount" className="block font-semibold text-gray-400">
              ({stock} Units available)
            </label> : <></>
          }

          <div className="flex flex-col gap-1">
            <select
              id="amount"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-indigo-400 block w-full lg:w-fit p-2.5 lg:py-2.5 lg:px-5 font-semibold"
              onChange={handleSelectChange}>
              <option value="1" id="select_amount_1" >1 Unit</option>
              <option value="2" id="select_amount_2" >2 Units</option>
              <option value="3" id="select_amount_3">3 Units</option>
              <option value="4" id="select_amount_4" >4 Units</option>
              <option value="select_amount_custom" id="select_amount_custom">Custom amount</option>
            </select>
            {customAmount && (
              <input
                type="number"
                name="input_amount_custom"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-indigo-400 w-fit p-2.5"
                placeholder="Enter custom amount..."
                defaultValue={1}
                min={1}
                max={!unlimited && stock > 0 ? stock : 999}
                required={true}
                //@ts-ignore
                onChange={(event) => {
                  //@ts-ignore
                  event.currentTarget.value = Math.round(event.currentTarget.value)
                  setCurrentAmount(Number(event.currentTarget.value))
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="px-4 text-right flex-1">
        <p className="text-lg font-bold text-indigo-400 totalPrice">${nowPrice}</p>
        <button className="text-red-400 hover:text-red-500 font-semibold flex items-center justify-center">
          <svg
            fill="currentColor"
            viewBox="0 0 32 32"
            version="1.1"
            width="26px"
            height="26px"
            className="cursor-pointer duration-75 inline-block"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M11.188 4.781c6.188 0 11.219 5.031 11.219 11.219s-5.031 11.188-11.219 11.188-11.188-5-11.188-11.188 5-11.219 11.188-11.219zM11.25 17.625l3.563 3.594c0.438 0.438 1.156 0.438 1.594 0 0.406-0.406 0.406-1.125 0-1.563l-3.563-3.594 3.563-3.594c0.406-0.438 0.406-1.156 0-1.563-0.438-0.438-1.156-0.438-1.594 0l-3.563 3.594-3.563-3.594c-0.438-0.438-1.156-0.438-1.594 0-0.406 0.406-0.406 1.125 0 1.563l3.563 3.594-3.563 3.594c-0.406 0.438-0.406 1.156 0 1.563 0.438 0.438 1.156 0.438 1.594 0z"></path>{" "}
            </g>
          </svg>
          Remove from wishlist
        </button>
      </div>

    </div>
  );
}

export default ProductCard
