export function ProductCard() {
  return (
    <div className="flex max-md:flex-col max-md:flex-1 max-md:min-w-[220px] max-md:gap-3 max-md:text-center max-md:bg-gray-50 max-md:py-8 flex-wrap items-center md:odd:bg-gray-50 rounded-lg px-6 py-3">
      <div className="px-4 flex-1">
        <div className="w-full flex flex-row items-center">
          <img
            src="https://static-00.iconduck.com/assets.00/games-roblox-icon-2048x2048-l2iw395r.png"
            alt="Alt"
            className="object-cover w-[128px]"
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col px-4">
        <h2 className="text-xl font-semibold text-gray-700">Product Name</h2>
        <p className="text-gray-500">
          By{" "}
          <a href="/authors/author" className="text-indigo-400">
            Product Author
          </a>
        </p>
      </div>
      <div className="px-4 block flex-1">
        <p className="text-lg font-bold text-indigo-400">$60.00</p>
        <span className="text-xs text-gray-500 line-through">$100.00</span>
      </div>
      <div className="px-4 text-right flex-1 flex flex-col gap-3 text-center">
        <button className="text-indigo-400 hover:text-indigo-500 font-semibold flex items-center justify-center gap-1">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            width="26px"
            height="26px"
            className="cursor-pointer text-indigo-500 hover:text-indigo-800 duration-75 inline-block"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M3 3H4.37144C5.31982 3 6.13781 3.66607 6.32996 4.59479L8.67004 15.9052C8.86219 16.8339 9.68018 17.5 10.6286 17.5H17.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
              <path
                d="M6.82422 7H19.6743C20.3386 7 20.8183 7.6359 20.6358 8.27472L19.6217 11.8242C19.2537 13.1121 18.0765 14 16.7371 14H8.27734"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
              <circle
                cx="16.5"
                cy="20.5"
                r="0.5"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></circle>{" "}
              <circle
                cx="0.5"
                cy="0.5"
                r="0.5"
                transform="matrix(1 0 0 -1 10 21)"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></circle>{" "}
            </g>
          </svg>
          Add to cart
        </button>
        <button className="text-red-400 hover:text-red-500 font-semibold flex items-center justify-center">
          <svg
            fill="currentColor"
            viewBox="0 0 32 32"
            version="1.1"
            width="26px"
            height="26px"
            className="cursor-pointer duration-75 inline-block"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M11.188 4.781c6.188 0 11.219 5.031 11.219 11.219s-5.031 11.188-11.219 11.188-11.188-5-11.188-11.188 5-11.219 11.188-11.219zM11.25 17.625l3.563 3.594c0.438 0.438 1.156 0.438 1.594 0 0.406-0.406 0.406-1.125 0-1.563l-3.563-3.594 3.563-3.594c0.406-0.438 0.406-1.156 0-1.563-0.438-0.438-1.156-0.438-1.594 0l-3.563 3.594-3.563-3.594c-0.438-0.438-1.156-0.438-1.594 0-0.406 0.406-0.406 1.125 0 1.563l3.563 3.594-3.563 3.594c-0.406 0.438-0.406 1.156 0 1.563 0.438 0.438 1.156 0.438 1.594 0z"></path>{" "}
            </g>
          </svg>
          Remove from wishlist
        </button>
      </div>
    </div>
  );
}
