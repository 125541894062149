import { useState, useEffect } from "react";
import ProductCard from "./Cards/ProductCard";
// @ts-ignore
import defaultProductImage from "../../assets/products/defaultProduct.webp"
import { useLocation } from "react-router-dom";


const defaultProducts = [
  {
    featuredTags: ["trending", "promoted"],
    image:
      defaultProductImage,
    providers: "Cloudy Coding",
    name: "Cloudy Coding",
    description:
      "Default product render",
    price: 299.99,
    stars: 5,
    author: "CloudyCoding",
    sales: 399,
    uuid: "CloudyCoding",
    offer: 0
  },
  {
    featuredTags: ["trending", "promoted"],
    image:
      defaultProductImage,
    providers: "Cloudy Coding",
    name: "Cloudy Coding",
    description:
      "Default product render",
    price: 299.99,
    stars: 5,
    author: "CloudyCoding",
    sales: 399,
    uuid: "CloudyCoding",
    offer: 0
  },
  {
    featuredTags: ["trending", "promoted"],
    image:
      defaultProductImage,
    providers: "Cloudy Coding",
    name: "Cloudy Coding",
    description:
      "Default product render",
    price: 299.99,
    stars: 5,
    author: "CloudyCoding",
    sales: 399,
    uuid: "CloudyCoding",
    offer: 0
  },
  {
    featuredTags: ["trending", "promoted"],
    image:
      defaultProductImage,
    providers: "Cloudy Coding",
    name: "Cloudy Coding",
    description:
      "Default product render",
    price: 299.99,
    stars: 5,
    author: "CloudyCoding",
    sales: 399,
    uuid: "CloudyCoding",
    offer: 0
  }
]

export default function MarketProducts() {

  const [productsData, setProductsData] = useState(defaultProducts);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  //@ts-ignore
  const entries = [...searchParams.entries()]
  const search = entries.map(entry => {
    return `${entry[0]}=${entry[1]}`
  }).join("&")


  useEffect(() => {
    (async () => {
      const data = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/products/get?" + search)
      const json = await data.json()

      const _products = await Promise.all(json.body.products.map(async (_product: any) => {
        const imageData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/images/get/" + _product.image)
        const image = await imageData.json()
        _product.image = image.status === "success" ? image.body.base64 : defaultProductImage
        return _product
      }))
      setProductsData(_products)
      window.dispatchEvent(new CustomEvent("emergency-resize"))
    })()
  }, [])


  return (
    <section className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 xl:max-w-[80%]">
      {productsData.map((product, index) => (
        <ProductCard
          key={index}
          uuid={product.uuid}
          image={product.image}
          name={product.name}
          price={product.price}
          stars={product.stars}
          description={product.description}
          offer={product.offer}
        ></ProductCard>
      ))}
    </section>
  );
}
