import { useRef, useEffect, useState } from "react";
import { Player } from "@lordicon/react";
import WarningIcon from "../../../../assets/icons/warning-alert.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeleteProductPreview } from "./DeleteProductPreview";
import { RequiredTick } from "../../../Customization/RequiredTick";
import { useLocation, Location, useNavigate } from "react-router-dom";

interface DeleteProductProps {
  productId: string;
  productImg: string;
  productName: string;
  closeModal: () => void;
}

export default function EditFileProduct({
  productId,
  productImg,
  productName,
  closeModal,
}: DeleteProductProps) {
  const [introducedId, setIntroducedId] = useState("");

  const playerRef = useRef<Player>(null);

  useEffect(() => {
    playerRef.current?.playFromBeginning();
  }, []);

  const navigator = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();



    if (productId !== introducedId) {
      toast.error("Product ID and introduced ID are not the same.");
      return;
    }

    await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/products/delete/" + productId)
    //@ts-ignore
    window.location.reload()
  };

  return (
    <form
      method="post"
      action="#"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-2 items-center justify-center py-4">
        <Player
          ref={playerRef}
          size={72}
          icon={WarningIcon}
          colorize="#f87171"
          onComplete={() =>
            setTimeout(() => {
              playerRef.current?.playFromBeginning();
            }, 500)
          }
        ></Player>
        <h2 className="font-bold text-xl">Warning!</h2>
        <span className="font-semibold text-lg">
          Are you sure you want to delete this product?
        </span>

        <DeleteProductPreview
          productId={productId}
          productImg={productImg}
          productName={productName}
        ></DeleteProductPreview>

        <div className="w-full mt-2">
          <label
            htmlFor="id"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Introduce product id
            <RequiredTick />
          </label>
          <input
            type="text"
            name="id"
            id="id"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 block w-full p-2.5"
            placeholder={productId}
            onChange={(e) => setIntroducedId(e.target.value)}
            required={true}
          />
        </div>

        <input type="hidden" name="originalProductId" value={productId} />
      </div>
      <div className="w-full flex flex-row gap-3 max-sm:flex-col justify-end items-center">
        <button
          type="button"
          onClick={closeModal}
          className="text-white inline-flex items-center bg-green-400 hover:bg-green-400/80 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          <svg
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="me-2 -ms-1 w-4 h-4"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M12.9998 8L6 14L12.9998 21"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
              <path
                d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
            </g>
          </svg>
          No, return
        </button>

        <button
          type="submit"
          className="text-white inline-flex items-center bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          <svg
            className="me-1 -ms-1 w-5 h-5 rotate-45"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          Yes, delete
        </button>
      </div>
    </form>
  );
}
