import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface NotificationProps {
  error: string | null;
  success: string | null;
}

let displayed = false

const RenderNotification: React.FC<NotificationProps> = ({
  error,
  success,
}) => {
  if (error && !displayed) {
    toast.error(error, {
      toastId: "a"
    });
    displayed = true;

  }
  if (success && !displayed) {
    toast.success(success, {
      toastId: "a"
    });
    displayed = true;
  }
  return <ToastContainer />;
};

export default RenderNotification;
