import { useEffect } from "react";
import { CheckUserLogged } from "../../utils/request/userInfo";
import { useNavigate } from "react-router-dom";

export function NeedUnprotected() {

  const navigation = useNavigate()

  async function control() {
    const response = await CheckUserLogged()
    if (response.body.logged) return navigation("/")
  }

  useEffect(() => {
    control()
  }, []);


  return (
    <div></div>
  )
}