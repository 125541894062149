import Stars from "../../Customization/Stars";

interface ProductProps {
  uuid: string;
  image: string;
  name: string;
  price: number;
  stars: number;
  description: string;
  offer: number;
}

const ProductCard: React.FC<ProductProps> = ({
  uuid,
  image,
  name,
  price,
  stars,
  description,
  offer,
}) => {
  return (
    <a
      href={`/marketplace/product/${uuid}`}
      className="relative flex w-full flex-col rounded-lg bg-white shadow-sm overflow-hidden border border-gray-200 duration-100 hover:shadow-lg"
    >
      <div className="relative min-h-[250px]">
        <div className="bg-gray-50 p-5 w-full flex justify-center items-center">
          <img
            className="h-48 w-full object-cover rounded-lg shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]"
            src={image}
            alt={name}
          />
        </div>
      </div>
      <div className="mt-4 px-5 pb-5 flex flex-col gap-2">
        {offer && offer > 0 ? (
          <div className="py-1 rounded-xl px-3 bg-indigo-200 w-fit">
            {offer}% off!
          </div>
        ) : ""}
        <h5 className="text-xl tracking-tight text-slate-900">
          {name}
        </h5>
        <div className="flex items-center justify-between">
          <div className="flex gap-2 items-end">
            <span className="text-3xl font-bold text-slate-900">
              {
                price > 0 ? "$" + (offer ? (price * (1 - (offer / 100))) : price) : "Free"
              }
            </span>
            {offer && offer > 0 ? (
              <span className="text-sm text-slate-900 line-through">
                $ {price}
              </span>
            ) : ""}
          </div>
          <div className="flex items-center">
            <Stars value={stars} width={5}></Stars>
            <span className="mr-2 ml-3 rounded bg-yellow-400 px-2.5 py-0.5 text-xs font-semibold text-gray-700">
              {stars}
            </span>
          </div>
        </div>
        <p className="text-gray-500">{description.slice(0, 100)}...</p>
      </div>
    </a>
  );
};

export default ProductCard;
