export default function ProfileSidebar() {
  return (
    <aside className="flex-1 h-fit py-6 bg-white shadow-md rounded-lg max-xl:w-full xl:max-w-[20%]">
      <div className="h-full flex flex-col">
        <div className="flex items-center justify-start gap-3 px-4">
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
          <h2 className="text-xl font-semibold text-black">Manage settings</h2>
        </div>
        <div className="mt-4 px-4">
          <div className="flex max-sm:flex-wrap">
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-900"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  ></path>
                </svg>
              </div>

              <input
                type="search"
                name="search"
                placeholder="Search for a specific option..."
                className="w-full max-sm:py-2.5 flex gap-3 p-2.5 h-full text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg pl-10 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
              />
            </div>
          </div>
        </div>
        <div className="mt-4 px-4">
          <p className="text-gray-400">Options</p>
        </div>
        <div className="mt-4 px-4 h-full overflow-auto">
          <div className="grid grid-cols-1 gap-4">
            <a
              href="/profile"
              className="bg-gray-50 hover:bg-gray-100 p-4 cursor-pointer rounded-md border border-gray-300 transition-colors duration-300 flex items-center justify-start gap-2"
            >
              <svg
                viewBox="0 0 400 400"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                className="cursor-pointer text-indigo-500 duration-75 inline-block"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M103 138.32C134.266 56.4075 216.688 35.0078 270.69 105.641"
                    stroke="currentColor"
                    strokeWidth="25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                  <path
                    d="M134.198 149.813C137.17 313.038 261.005 313.038 270.69 181.764"
                    stroke="currentColor"
                    strokeWidth="25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                  <path
                    d="M126.47 140.918C188.067 138.32 292.39 58.018 287.419 148.71"
                    stroke="currentColor"
                    strokeWidth="25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                  <path
                    d="M103 358.732C129.759 311.598 168.322 296.081 180.958 304.301C188.104 308.95 204.456 322.806 209.593 331.685C218.487 347.054 232.308 298.322 240.689 296.081C265.256 289.508 303.955 337.227 316.187 363"
                    stroke="currentColor"
                    strokeWidth="25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                </g>
              </svg>
              <h3 className="text-md font-semibold text-black">My profile</h3>
            </a>
            <a
              href="/profile/products"
              className="bg-gray-50 hover:bg-gray-100 p-4 cursor-pointer rounded-md border border-gray-300 transition-colors duration-300 flex items-center justify-start gap-2"
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                className="cursor-pointer text-indigo-500 duration-75 inline-block"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M21.9844 10C21.9473 8.68893 21.8226 7.85305 21.4026 7.13974C20.8052 6.12523 19.7294 5.56066 17.5777 4.43152L15.5777 3.38197C13.8221 2.46066 12.9443 2 12 2C11.0557 2 10.1779 2.46066 8.42229 3.38197L6.42229 4.43152C4.27063 5.56066 3.19479 6.12523 2.5974 7.13974C2 8.15425 2 9.41667 2 11.9415V12.0585C2 14.5833 2 15.8458 2.5974 16.8603C3.19479 17.8748 4.27063 18.4393 6.42229 19.5685L8.42229 20.618C10.1779 21.5393 11.0557 22 12 22C12.9443 22 13.8221 21.5393 15.5777 20.618L17.5777 19.5685C19.7294 18.4393 20.8052 17.8748 21.4026 16.8603C21.8226 16.1469 21.9473 15.3111 21.9844 14"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>{" "}
                  <path
                    d="M21 7.5L17 9.5M12 12L3 7.5M12 12V21.5M12 12C12 12 14.7426 10.6287 16.5 9.75C16.6953 9.65237 17 9.5 17 9.5M17 9.5V13M17 9.5L7.5 4.5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
              <h3 className="text-md font-semibold text-black">My products</h3>
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
}
