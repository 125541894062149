interface DeleteProductPreviewProps {
  productId: string;
  productImg: string;
  productName: string;
}

export function DeleteProductPreview({ productId, productImg, productName }: DeleteProductPreviewProps) {
  return (
    <div className="mt-2 w-full flex max-sm:flex-col max-md:flex-1 max-md:min-w-[220px] max-md:gap-3 max-md:text-center max-md:py-5 flex-wrap items-center bg-gray-100 border-gray-300 border rounded-lg px-1 py-3">
      <div className="px-4 flex-1">
        <div className="w-full flex flex-row items-center justify-center">
          <img
            src={productImg}
            alt={productName}
            className="object-cover min-w-[96px] w-[128px]"
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col text-left px-4">
        <h2 className="text-xl font-semibold text-gray-700">{productName}</h2>
        <span className="text-gray-500">ID: {productId}</span>
      </div>
    </div>
  );
}
