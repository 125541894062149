"use client";

import { useState, useEffect } from "react";
import { navbarItems } from "./Navbar.data";
//@ts-ignore
import logo from "../../assets/logos/logo.webp"
import { NavbarItems } from "./NavbarItems";

export default function Navbar() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);



  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = (index: any) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <nav
      className={`top-0 z-50 backdrop-blur-sm max-lg:bg-gray-50/75 max-lg:fixed max-lg:mb-8 max-lg:w-full lg:sticky transition duration-300 ${isScrolled ? "bg-gray-50/75" : ""
        }`}
    >
      <section
        className={`container mx-auto flex items-center justify-between relative py-3 sm:px-10 px-4 border-gray-200 border-b min-h-[75px] font-['Poppins']`}
      >
        <div className="flex items-center gap-2 font-semibold text-lg">
          <img
            src={logo}
            alt="logo"
            className="w-10"
          />
          <a
            className="hidden lg:block"
            href={"/"}
          >
            <p className="text-xs">Cloudy Coding</p>
            <p className="text-xl">Market</p>
          </a>
        </div>
        <NavbarItems></NavbarItems>
      </section>
      <div className="container mx-auto flex flex-wrap justify-center px-10 py-3 relative font-['Raleway']">
        <div
          id="toggle"
          onClick={() => setOpenNavbar(!openNavbar)}
          className="flex ml-auto lg:order-1 lg:hidden relative z-50"
        >
          <button className="ml-7">
            <svg
              className="w-7 h-7"
              fill="#000"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <ul
          id="collapseMenu"
          className={`lg:!flex lg:space-x-10 max-lg:space-y-3 max-lg:hidden max-lg:w-full max-lg:my-4 ${openNavbar && "!block"
            }`}
        >
          {navbarItems.map((item: any, index: any) => (
            <li key={index} className="max-lg:border-b max-lg:py-2 relative">
              {item.link ? (
                <a
                  href={item.link}
                  className="hover:text-indigo-500 text-gray-600 font-semibold text-[15px] block"
                >
                  {item.title}
                </a>
              ) : (
                <span
                  className="hover:text-indigo-500 text-gray-600 font-semibold text-[15px] block hover:cursor-pointer"
                  onClick={() => toggleMenu(index)}
                >
                  {item.title}
                  {item.submenu && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      className="ml-1 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 16a1 1 0 0 1-.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1-.7.29z"
                        data-name="16"
                        data-original="#000000"
                      />
                    </svg>
                  )}
                </span>
              )}

              {item.submenu && index === openMenuIndex && (
                <ul className="absolute block shadow-lg bg-white space-y-2 px-6 pb-4 pt-6 mt-3 max-sm:w-full sm:min-w-[250px] z-50">
                  {item.submenu.map((subItem: any, subIndex: any) => (
                    <li key={subIndex} className="border-b py-3">
                      <a
                        href={subItem.link}
                        className="hover:text-[#007bff] hover:fill-[#007bff] text-gray-600 font-semibold text-[15px] block"
                      >
                        {subItem.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
