export function ModalLayout({
  title,
  small,
  returnModal,
  smoothBackground=false,
  closeModal,
  children,
}: any) {
  return (
    <div
      tabIndex={-1}
      className={`fixed inset-0 bottom-0 overflow-y-auto z-[20000] justify-center items-center max-w-screen h-screen flex items-center justify-center ${smoothBackground ? "bg-black/10" : "bg-black/30"}`}
      onClick={closeModal}
    >
      <div
        className={`relative ${
          small
            ? "max-w-[60%] max-sm:min-w-[80%] max-md:min-w-[70%] max-xl:min-w-[60%] xl:w-[30%]"
            : "w-full max-w-[70%] max-sm:max-w-[95%] max-md:max-w-[85%] max-xl:max-w-[80%]"
        } transition duration-100 h-full flex items-center justify-center`}
      >
        <div
          className="relative bg-white rounded-lg shadow w-full transition duration-100"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
            <div className="flex items-center justify-center gap-1">
              {returnModal && (
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  onClick={returnModal}
                  data-modal-toggle="crud-modal"
                >
                  <svg
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M12.9998 8L6 14L12.9998 21"
                        stroke="currentColor"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                      <path
                        d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984"
                        stroke="currentColor"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                  <span className="sr-only">Return</span>
                </button>
              )}
              {closeModal && (
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  onClick={closeModal}
                  data-modal-toggle="crud-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              )}
            </div>
          </div>
          <div className="p-4 md:p-5">{children}</div>
        </div>
      </div>
    </div>
  );
}
