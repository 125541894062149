import { Wishlist } from "../../../../Global/classes/Wishlist.js"
import { Cart } from "../../../../Global/classes/Cart.js"
import secureLocalStorage from "react-secure-storage"
import { IAPIResponse } from "../../../../Global/classes/APIResponses.js"
import { postRequest } from "../postRequest"


export async function CheckUserLogged() {
  // const loggedData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/auth/logged/check")

  // const loggedJSON = await loggedData.json().catch(err => {
  //   if (err) return { body: { logged: false } }
  // }) as IAPIResponse
  // secureLocalStorage.setItem("logged", loggedJSON.body)
  // return loggedJSON as IAPIResponse

  const loggedData = await postRequest(process.env.REACT_APP_API_WEB_BASE_URL + "/auth/logged/check", {}) as IAPIResponse

  secureLocalStorage.setItem("logged", loggedData.body)
  return loggedData as IAPIResponse
}

export async function GetUserCart() {
  const cartData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/profile/getCart")
  const cart = await cartData.json().catch(err => {
    if (err) return new Cart()
  })
  secureLocalStorage.setItem("logged", cart.body)
  return cart.body
}


export async function GetUserWishlist() {
  const wishlistData = await fetch(process.env.REACT_APP_API_WEB_BASE_URL + "/profile/getWishlist")
  const wishlist = await wishlistData.json().catch(err => {
    if (err) return new Wishlist()
  })
  secureLocalStorage.setItem("logged", wishlist.body)
  return wishlist.body
}