// React - react-select imports
import { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";

// Import navigation and scroll components
import MarketNavbar from "../../Navigation/Market/MarketNavbar";
import Footer from "../../Navigation/Footer";
import ProfileSidebar from "../../Navigation/Profile/ProfileSidebar";
import ScrollMotion from "../../Scroll/ScrollMotion";

// Modal layout import
import { ModalLayout } from "../../Profile/Products/Modals/ModalLayout";

// Create products components
import ThirdPartyProduct from "../../Profile/Products/Create/Types/ThirdPartyProduct";
import ServiceProduct from "../../Profile/Products/Create/Types/ServiceProduct";
import FileProduct from "../../Profile/Products/Create/Types/FileProduct";

// Edit products components
import EditThirdPartyProduct from "../../Profile/Products/Edit/Types/EditThirdPartyProduct";
import EditServiceProduct from "../../Profile/Products/Edit/Types/EditServiceProduct";
import EditFileProduct from "../../Profile/Products/Edit/Types/EditFileProduct";

// Delete products components
import DeleteProduct from "../../Profile/Products/Delete/DeleteProduct";

// Product and no products imports
import { Product } from "../../Profile/Products/Product";

//@ts-ignore
import productLogo from "../../../assets/products/defaultProduct.webp"


import { getQueryParams } from "../../../utils/getQueryParams";
import { useLocation } from "react-router-dom";

import RenderNotification from "../../Notifications/RenderNotification";
import ErrorBoundary from "../Errors/ErrorBoundary";
import { updateProductsView } from "../../../utils/createProductRequest";


export default function Products() {
  const location = useLocation();
  const { error, success } = getQueryParams(location);



  // Input select options
  const options = [
    {
      value: "non-selected",
      label: "Select product type",
      defaultInputValue: true,
    },
    {
      value: "thirdParty",
      label: "Third-Party Product",
    },
    {
      value: "service",
      label: "Service Product",
    },
    {
      value: "file",
      label: "File Product",
    },
  ];

  // Input select styles
  const selectStyle: StylesConfig = {
    valueContainer: (styles) => {
      styles.padding = "5px 10px";
      styles.fontFamily =
        'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
      styles.fontSize = "14px";
      styles.color = "rgb(17, 24, 39)";
      styles.fontWeight = "600";
      styles.borderRadius = "30px !important";
      styles.caretColor = "transparent";
      return styles;
    },
    control: (styles) => {
      styles.fontFamily =
        'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
      styles.fontSize = "14px";
      styles.color = "rgb(17, 24, 39)";
      styles.fontWeight = "600";
      styles.borderRadius = "5px ";
      styles.minWidth = "25vh";
      return styles;
    },
  };

  /* App states */
  // Creating new product
  const [isCreatingNewProduct, setCreatingNewProduct] = useState(false);
  // Selected product type
  const [selectedType, setSelectedType] = useState("");
  // Product type
  const [productType, setProductType] = useState("");
  // Editing product
  const [editingProduct, setEditingProduct] = useState<any>(null);
  // Deleting product
  const [deletingProduct, setDeletingProduct] = useState<any>(null);

  const [products, setProducts] = useState([
    {
      productId: 1,
      productType: "service",
      productImg:
        productLogo,
      productName: "Product N°1",
      productDescription:
        "Default description",
      productPrice: 60,
      productOriginalPrice: 100,
      productStock: 10,
      productService:
        "Default service",
      unlimited: false
    }
  ])


  useEffect(() => {
    // getProducts()
    updateProductsView(setProducts)
  }, [])

  // When user changes product type
  const handleProductTypeChange = (selectedOption: any) => {
    setSelectedType(selectedOption.value);
  };

  // When user submit selected product type
  const handleProductTypeSubmit = () => {
    setProductType(selectedType);
  };

  // When user starts creating new product
  const handleCreateProduct = () => {
    setCreatingNewProduct(true);
  };

  // When user selects edit option in a product
  const handleEditProduct = (productId: number) => {
    const productToEdit = products.find(
      (product) => product.productId === productId
    );
    setEditingProduct(productToEdit);
  };

  // When user selects delete option in a product
  const handleDeleteProduct = (productId: number) => {
    const productToDelete = products.find(
      (product) => product.productId === productId
    );
    setDeletingProduct(productToDelete);
  };

  // When user closes a modal
  const handleCloseModal = () => {
    // If user was creating a product
    if (isCreatingNewProduct) {
      setCreatingNewProduct(false);
      setProductType("");
    }

    // If user was editing a product
    if (editingProduct) {
      setEditingProduct(null);
    }

    // If user was deleting a product
    if (deletingProduct) {
      setDeletingProduct(null);
    }
  };

  // Handle return button in creating modals
  const handleReturn = () => {
    setProductType("");
  };

  // Render form function
  const renderForm = (
    title: string,
    component: any,
    returnModal = true,
    small = false
  ) => {
    return (
      <ModalLayout
        title={title}
        returnModal={returnModal && handleReturn}
        small={small}
        closeModal={handleCloseModal}
      >
        {component}
      </ModalLayout>
    );
  };

  // Render create product forms
  const renderCreateForm = () => {
    switch (productType) {
      case "thirdParty":
        return renderForm(
          "Create Third-Party product",
          <ThirdPartyProduct closeModal={handleCloseModal} setProducts={setProducts} />
        );
      case "service":
        return renderForm(
          "Create Service product",
          <ServiceProduct closeModal={handleCloseModal} setProducts={setProducts} />
        );
      case "file":
        return renderForm(
          "Create File product",
          <FileProduct closeModal={handleCloseModal} setProducts={setProducts} />
        );
      default:
        return null;
    }
  };

  // Render edit product forms
  const renderEditForm = () => {
    if (!editingProduct) return null;

    const title = `Edit ${editingProduct.productType === "thirdParty"
      ? "Third-Party"
      : editingProduct.productType
      } product (${editingProduct.productName})`;

    switch (editingProduct.productType.toLowerCase()) {
      case "thirdParty":
        return renderForm(
          title,
          <EditThirdPartyProduct
            content={editingProduct}
            closeModal={handleCloseModal}
          />,
          false
        );
      case "service":
        return renderForm(
          title,
          <EditServiceProduct
            content={editingProduct}
            closeModal={handleCloseModal}
          />,
          false
        );
      case "file":
        return renderForm(
          title,
          <EditFileProduct
            content={editingProduct}
            closeModal={handleCloseModal}
          />,
          false
        );
      default:
        return null;
    }
  };

  // Render delete product forms
  const renderDeleteForm = () => {
    if (!deletingProduct) return null;

    return renderForm(
      `Deleting product (${deletingProduct.productName})`,
      <DeleteProduct
        productId={deletingProduct.productId}
        productImg={deletingProduct.productImg}
        productName={deletingProduct.productName}
        closeModal={handleCloseModal}
      />,
      false,
      true
    );
  };

  return (
    <ErrorBoundary>
      <RenderNotification error={error} success={success} />

      {isCreatingNewProduct && (
        <>
          {(productType === "non-selected" || productType === "") && (
            <ModalLayout
              title="Create new product"
              small={true}
              closeModal={handleCloseModal}
            >
              <p className="mb-4 text-gray-600">
                You are looking to create a new product, which can be a file, a
                service or an external product, here you must select the type of
                product you want to create in order to continue with the
                creation process
              </p>
              <Select
                options={options}
                id="productType"
                defaultValue={options[0]}
                onChange={handleProductTypeChange}
                styles={selectStyle}
              ></Select>
              <div className="mt-4 w-full flex items-center justify-end">
                <button
                  type="button"
                  onClick={handleProductTypeSubmit}
                  disabled={
                    selectedType === "non-selected" || selectedType === ""
                  }
                  className={`text-white inline-flex items-center disabled:bg-indigo-300 disabled:hover:indigo-300 disabled:cursor-not-allowed bg-indigo-400 hover:bg-indigo-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                >
                  <svg
                    fill="currentColor"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="me-2 -ms-1 w-3 h-3"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <g id="next">
                        {" "}
                        <g>
                          {" "}
                          <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 "></polygon>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                  Continue
                </button>
              </div>
            </ModalLayout>
          )}
          {renderCreateForm()}
        </>
      )}
      {editingProduct && <>{renderEditForm()}</>}
      {deletingProduct && <>{renderDeleteForm()}</>}
      <ScrollMotion>
        <MarketNavbar></MarketNavbar>
        <main className="bg-gray-100/50 pt-44 lg:pt-16 pb-20 max-sm:px-4 flex flex-col gap-6 xl:gap-12">
          <div className="flex flex-wrap max-xl:flex-col xl:flex-row gap-6 xl:gap-12 container mx-auto">
            <ProfileSidebar></ProfileSidebar>
            <section className="flex-1 py-6 px-8 flex flex-col gap-6 bg-white shadow-md xl:max-w-[80%] rounded-lg">
              <div className="w-full">
                <h3 className="text-2xl font-bold text-navy-700">
                  Your products
                </h3>
                <p className="mt-2 text-base text-gray-600">
                  Access the control panel to manage your products. Easily
                  create, edit, and delete products according to your needs.
                  Keep your inventory up-to-date and maximize your business
                  efficiency with our product management tool.
                </p>
              </div>

              <div className="flex-1 flex flex-row flex-wrap items-between gap-2">
                <button
                  onClick={() => handleCreateProduct()}
                  className="max-sm:w-full sm:flex-1 flex flex-row gap-2 items-center justify-center text-gray-600 bg-green-400/80 hover:bg-green-400 duration-100 py-2 rounded-lg"
                >
                  <svg
                    viewBox="0 0 512 512"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="30px"
                    height="30px"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>new-indicator</title>{" "}
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        {" "}
                        <g
                          id="scheduler"
                          fill="currentColor"
                          transform="translate(85.333333, 85.333333)"
                        >
                          {" "}
                          <path
                            d="M170.666667,1.42108547e-14 C264.923264,-3.10380131e-15 341.333333,76.4100694 341.333333,170.666667 C341.333333,264.923264 264.923264,341.333333 170.666667,341.333333 C76.4100694,341.333333 2.57539587e-14,264.923264 1.42108547e-14,170.666667 C2.6677507e-15,76.4100694 76.4100694,3.15255107e-14 170.666667,1.42108547e-14 Z M170.666667,42.6666667 C99.9742187,42.6666667 42.6666667,99.9742187 42.6666667,170.666667 C42.6666667,241.359115 99.9742187,298.666667 170.666667,298.666667 C241.359115,298.666667 298.666667,241.359115 298.666667,170.666667 C298.666667,99.9742187 241.359115,42.6666667 170.666667,42.6666667 Z M192,85.3333333 L191.999333,149.333333 L256,149.333333 L256,192 L191.999333,191.999333 L192,256 L149.333333,256 L149.333333,191.999333 L85.3333333,192 L85.3333333,149.333333 L149.333333,149.333333 L149.333333,85.3333333 L192,85.3333333 Z"
                            id="Combined-Shape"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                  <h2 className="font-bold">Create new</h2>
                </button>
              </div>
              <div className="flex flex-col gap-4">
                {/* <NoProducts></NoProducts> */}
                {products.map((product, index) => (
                  <Product
                    key={index}
                    productId={product.productId}
                    productImg={product.productImg}
                    productType={product.productType}
                    productName={product.productName}
                    productPrice={product.productPrice}
                    productOriginalPrice={product.productOriginalPrice}
                    productStock={product.productStock}
                    handleEdit={handleEditProduct}
                    handleDelete={handleDeleteProduct}
                    unlimited={product.unlimited}
                  ></Product>
                ))}
              </div>
            </section>
          </div>
        </main>
        <Footer></Footer>
      </ScrollMotion>
    </ErrorBoundary>
  );
}
