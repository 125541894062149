export function StatBadge({ stat, title, subtitle }: any) {
  return (
    <div className="bg-white flex-1 min-w-[40%] shadow rounded-lg px-4 py-6 sm:px-6 sm:py-7 xl:px-8 xl:py-8">
      <div className="flex items-center">
        <div className="flex-1 text-center">
          <div className="flex justify-center items-center gap-2">
            <span className="text-2xl sm:text-3xl leading-none font-bold text-indigo-400">
              {stat}
            </span>
            <h3 className="text-2xl sm:text-3xl leading-none font-semibold text-gray-900">
              {title}
            </h3>
          </div>
          <span className="text-base font-normal text-gray-500">
            {subtitle}
          </span>
        </div>
      </div>
    </div>
  );
}
